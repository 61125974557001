import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useSelector } from "react-redux";
import { store } from "../../shared";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DoneIcon from '@mui/icons-material/Done';
import { setScroll } from "../../shared/slice/scroll/ScrollSlice";
import { deleteNotification, getNotification, putNotification } from "../../shared/slice/ModalNotification/NotificationService";
import { setNotification } from "../../shared/slice/ModalNotification/ModalNotification";
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import QrCode from 'react-qr-code';
export default function Notification() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [selectedNotificationIndex, setSelectedNotificationIndex] = useState(null);
  const handleClose = () => {
    setSelectedNotificationIndex(null);
    setShow(false);
    store.dispatch(setScroll({ scroll: 0 }));
  }
  const handleShow = () => {
    setSelectedNotificationIndex(true);
  };
  const Notifications = useSelector((state) => state.ModalNotification.Notification);
  const loggedInUser = useSelector((state) => state.authentification.loggedInUser);
  const loggedInGuest = useSelector((state) => state.authentification.loggedInGuest);
  useEffect(() => {
    if (loggedInUser || loggedInGuest) {
      const fetchNotifications = async () => {
        try {
         
          store.dispatch(setNotification({ Notification: await getNotification(loggedInUser?._id || loggedInGuest?._id) }));
        } catch (error) {
          console.error('Error fetching notifications:', error);
        }
      };
      fetchNotifications();
    }
  }, [loggedInUser , loggedInGuest]);

  const [backgroundColors, setBackgroundColors] = useState({});
  useEffect(() => {
    const savedColors = JSON.parse(localStorage.getItem('notificationColors'));
    if (savedColors) {
      setBackgroundColors(savedColors);
    }
  }, []);
  const [openDialogIndex, setOpenDialogIndex] = useState(null);
  const handleOrderItemClick = async (index, orderId) => {
    try {

      await putNotification(orderId, loggedInUser?._id || loggedInGuest?._id);
      store.dispatch(setNotification({ Notification: await getNotification(loggedInUser?._id || loggedInGuest?._id) }));
      setOpenDialogIndex(index);
      const randomColor = "#fff";
      const updatedColors = {
        ...backgroundColors,
        [orderId]: randomColor
      };
      localStorage.setItem('notificationColors', JSON.stringify(updatedColors));
      setBackgroundColors(updatedColors);
    } catch (error) {
      console.error('Error updating notification:', error);
    }
  };
  const handleCloseDialog = () => {
    setOpenDialogIndex(null);
  };
  function formatTimeAgo(notificationDate) {
    const currentDate = new Date();
    const diffInMilliseconds = currentDate - notificationDate;
    const seconds = Math.floor(diffInMilliseconds / 1000);
    if (seconds < 60) {
      return seconds + " sec ago";
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return minutes + " min ago";
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return hours + " hours ago";
    } else {
      const days = Math.floor(seconds / 86400);
      return days + " days ago";
    }
  }

  useEffect(() => {
    const fetchNotifications = () => {
      try {
        const notifications = deleteNotification(loggedInUser?._id || loggedInGuest?._id);
        console.log("delete notifications !", notifications);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };
    fetchNotifications();
  }, []);
  return (
    <>
      <button onClick={() => handleShow()} className="btn">
        <NotificationsActiveIcon style={{ color: "var(--primaryColor)" }} />
      </button>
      <StyledOffCanvas show={selectedNotificationIndex !== null} onHide={handleClose} scroll={true} placement="end">
        <StyledOffCanvasHeader closeButton style={{ display: "block" }}>
          <Offcanvas.Title>
            <h2>{t("Your Notification")}</h2>
          </Offcanvas.Title>
        </StyledOffCanvasHeader>
        <StyledOffCanvasBody>
          {Notifications?.map((notification, index) => (
            <div key={index}>
              <OrderItem onClick={() => { handleOrderItemClick(index, notification.orderid) }}
                style={{ backgroundColor: backgroundColors[notification.orderid] || '#E6E6E6' }}>
                <div>
                  {t("Your order #")} {notification._id.substring(notification._id.length - 5)},
                  {t(" Has been")} {notification.message},
                </div>
                <br />
                <div>
                  <ProductPanierDescription>{formatTimeAgo(new Date(notification.date))}</ProductPanierDescription>
                </div>
              </OrderItem>

              {openDialogIndex === index && (
                <Dialog open={true} onClose={handleCloseDialog}>
                  <StyledOffCanvasBody>
                    <OrderItems>
                      <h4 style={{ textTransform: 'uppercase' }}>
                        {t("Your order")} #{notification._id.substring(notification._id.length - 4)},
                       
                          {notification.message === 'ready' ? (
                             <h4 className="d-flex justify-center" style={{ color: '#2CC94F' }}>
                            <>{t("is")} {notification.message} <DoneIcon /></>
                            </h4>
                          ) : notification.message === 'missed' ? (
                            <h4 className="d-flex justify-center" style={{ color: '#E74C3C' }} >
                            <>{t("is")} {notification.message} </>
                            </h4>
                           ) : notification.message === 'rejected' ? (
                            <h4 className="d-flex justify-center" style={{ color: '#E74C3C' }} >
                            <>{t("is")} {notification.message} </>
                            </h4>
                          ) : (
                            <h4 className="d-flex justify-center" style={{ color: '#2CC94F' }}>
                            <>{t("is")} {notification.message} </>
                            </h4>
                            )}
                          <IconButton aria-label="close" onClick={handleCloseDialog} style={{ position: 'absolute', top: '8px', right: '8px' }}>
                            <CloseIcon />
                          </IconButton>
                       
                        <br />
                        <div className="d-flex justify-center">
                          <QrCode style={{ width: '100px', height: '100px' }} value={notification.orderid} />
                        </div>
                      </h4>
                    </OrderItems>
                  </StyledOffCanvasBody>
                </Dialog>
              )}
            </div>
          ))}
        </StyledOffCanvasBody>
      </StyledOffCanvas>
    </>
  );
}
const StyledOffCanvas = styled(Offcanvas)`
  background-color: #F5F5F5;
  border-radius: 20px 0 0 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  @media (max-width: 768px) {
    background-color: #F5F5F5;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    width: 90% !important;
  }
`;
const StyledOffCanvasHeader = styled(Offcanvas.Header)`
  padding: 20px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  .btn-close {
    position: absolute;
    top: 25px;
    right: 35px;
  }
`;
const StyledOffCanvasBody = styled(Offcanvas.Body)`
  padding: 20px;
  background-color: #fff;
`;
const PriceValue = styled.span`
  color: var(--primaryColor);
`;
const CommanderButton = styled.button`
  // background-color: var(--primaryColor);
  background: var(--primaryColor);
  color: #fff;
  border: none;
  border-radius: 15px;
  font-size: 18px;
  margin-left: 10px;
  padding: 10px;
  &:hover {
    background-color: var(--primaryColorLight);
  }
`;
/* Order item styles */
const OrderItem = styled.div`
  margin-bottom: 5px;
  background-color: #E6E6E6;
  padding: 20px;
`;
const OrderItems = styled.div`
  margin-bottom: 5px;
  background-color: #fff;
  padding: 20px;
`;
// const OrderHeader = styled.div`
//   display: flex;
//   flex-direction: column-reverse;
//   justify-content: space-between;
//   align-items: center;
//   text-align: center;
// `;
// const ItemName = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;
// const ItemImage = styled.img`
//   width: 150px;
//   height: 100px;
//   object-fit: contain;
//   margin-bottom: 15px;
// `;
// const ItemQuantity = styled.div`
//   box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
//     rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
//   border-radius: 25px;
//   height: 40px;
//   width: 60%;
//   margin-right: 40%;
//   margin-bottom: 15px;
// `;
const ProductPanierDescription = styled.p`
  font-size: 14px;
  // font-style: italic;
  margin-bottom: 10px;
  color: #666;
  margin-left: 0px !important;
`;
// const Options = styled.div`
//   margin-bottom: 10px;
// `;
// const ProductOptions = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// `;
// const OptionsHeading = styled.h6`
//   font-size: 16px;
//   font-weight: bold;
//   margin-bottom: 5px;
// `;
// const Option = styled.p`
//   font-size: 14px;
//   // font-style: italic;
//   margin-bottom: 5px;
//   color: #666;
// `;
// const PriceContainer = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   align-items: end;
// `;
// const ClearCart = styled.div`
//   display: flex;
//   align-items: flex-end;
//   color: #fff;
//   padding: 0.375rem 0.75rem;
//   border-radius: 0.25rem;
//   background-color: #DC3545;
//   border-color: #DC3545;
//   border: 1px solid transparent;
//   padding: 0.375rem 0.75rem;
//   font-size: 1rem;
//   line-height: 1.5;
//   &:hover {
//     color: #fff;
//     background-color: #C82333;
//     border-color: #BD2130;
//     cursor: pointer;
//   }
// `;
// const TotalPrice = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   margin-top: 20px;
//   border-radius: 10px;
//   background-color: #fff;
//   width: 100%;
//   font-weight: bold;
// `;
// const PromoTitle = styled.h6`
//   margin-left: 3px;
//   font-size: 20px;
//   border-radius: 10px;
//   padding: 5px;
//   text-align: center;
// `;
// const PromoContainer = styled.div`
//   margin-bottom: 20px;
//   border-radius: 25px;
//   background-color: #fff;
//   box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
//   padding: 20px;
// `;
// const PromoDeleteButton = styled.div`
//   box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
//     rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
//   width: 40%;
//   border-radius: 25px;
//   text-align: center;
//   padding: 6px;
//   margin-bottom: 10px;
// `;