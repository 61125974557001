import React, { useContext, useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useSelector } from "react-redux";
import { BaseURI, store } from "../../shared";
import styled from "styled-components";
import { setOrder } from "../../shared/slice/order/OrderSlice";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  deletePromos,
  resetPromo,
  setSelectedPromos,
} from "../../shared/slice/promos/PromosSlice";
import { setModalPrincipal } from "../../shared/slice/ModalLogin/ModalLoginSlice";
import Checkout from "../Checkout/Checkout";
import { useTranslation } from "react-i18next";
import { getMode } from "../../shared/slice/restaurant/RestaurantService";
import { setScroll } from "../../shared/slice/scroll/ScrollSlice";
import { setmodelchekoutButton } from "../../shared/slice/auth/AuthSlice";
export default function Cart() {
  const { t } = useTranslation();
  const scroll = useSelector((state) => state.scroll.scroll);
  const [show, setShow] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceHt, setTotalPriceHt] = useState(0);
  const [modalCheckout, setModalCheckout] = useState(false);
  // const [frais, setFrais] = useState(null);
  // const [taux, setTaux] = useState(null);
  // const [apply, setApply] = useState("");
  // const [modeName, setModeName] = useState("");
  const handleClose = () => {
    setShow(false);
    store.dispatch(setScroll({ scroll: 0 }));
  }
  const handleShow = () => setShow(true);
  const currency = getSymbolFromCurrency(
    useSelector((state) => state.restaurant.menu?.currency)
  );
  const isLoggedIn = useSelector((state) => state.authentification.isLoggedIn);
  const orders = useSelector((state) => state.order.order);
  const selectedPromos = useSelector((state) => state.promos.selectedPromos);
  const restaurantSelected = useSelector(
    (state) => state.restaurant.restaurantSelected
  );
  const modeSelectedId = useSelector(
    (state) => state.restaurant.modeSelectedId
  );
  const frais = useSelector((state) => state.restaurant.frais);
  const taux = useSelector((state) => state.restaurant.taux);
  const apply = useSelector((state) => state.restaurant.apply);
  const modeName = useSelector((state) => state.restaurant.modeName);

  useEffect(() => {
    if (show) {
      store.dispatch(setScroll({ scroll: 1}));
    }
  }, [show]);

  // useEffect(() => {
  //   const getmode = async () => {
  //     if (modeSelectedId) {
  //       const mode = await getMode(modeSelectedId);
  //       if (mode.mode.applyTaux) {
  //         setTaux(mode.mode.taux);
  //       } else {
  //         setFrais(mode.mode.frais)
  //       };
  //       setApply(mode.mode.applicationType);
  //       setModeName(mode.mode.name);
  //     }
  //   };
  //   getmode();
  // }, [modeSelectedId, restaurantSelected._id]);

  // console.log(selectedPromos);

  useEffect(() => {
    let total = 0;
    let totalHt = 0;
    for (let i = 0; i < orders.length; i++) {
      // if (apply === "product") {
      //   total +=
      //     orders[i].price +
      //     (frais !== null ? (frais * orders[i].quantity) : orders[i].price * ((taux / 100) * orders[i].quantity));
      // } else {
      total += orders[i].price;
      totalHt += (orders[i].priceHt);
      // }
    }

    // order.product.id
    for (let i = 0; i < selectedPromos.length; i++) {
      for (let j = 0; j < selectedPromos[i].products.length; j++) {
        const item = selectedPromos[i].products[j];
        // if (apply === "product") {
        //   total +=
        //     item.price_after_discount +
        //     (frais !== null ? (frais * item.quantity) : item.price * ((taux / 100) * item.quantity));
        // } else {
        total += item.price_after_discount;
        totalHt += item.priceHt;
        // }
      }
    }
    if (apply === "order") {
      setTotalPrice(
        parseFloat(
          total + (frais !== null ? frais : total * (taux / 100))
        ).toFixed(2)
      );
      setTotalPriceHt(
        parseFloat(
          totalHt + (frais !== null ? frais : total * (taux / 100))
        ).toFixed(2)
      );
    } else {
      setTotalPrice(parseFloat(total.toFixed(2)));
      setTotalPriceHt(parseFloat(totalHt.toFixed(2)));
    }
  }, [orders, selectedPromos,
    // frais, taux
  ]);
//  console.log("orders",orders);

const addItem = (productId,id) => {
  const updatedOrders = orders.map((order, index) => {
    if (index === productId) {
      // Increment the quantity and update the price
      const newQuantity = order.quantity + 1;
      // const newPrice = ;
      localStorage.setItem(`QuantityInCart_${id}`, newQuantity);
      return {
        ...order,
        quantity: newQuantity,
        price: (order.unitePrice * newQuantity) ,
        priceHt: (order.unitePriceHt * newQuantity) ,
      };
    }
    return order;
  });
  store.dispatch(setOrder({ order: updatedOrders }));
};
const deleteItem = (productId, id) => {
  const updatedOrders = orders.map((order, index) => {
      if (index === productId) {
          // Check if quantity is greater than 1
          if (order.quantity > 1) {
              // If yes, decrement the quantity and update the price
              const newQuantity = order.quantity - 1;
              const newPrice = parseFloat(((order.unitePrice * newQuantity) ).toFixed(3));
              const newPriceHt = parseFloat(((order.unitePriceHt * newQuantity) ).toFixed(3));
              localStorage.setItem(`QuantityInCart_${id}`, newQuantity);
              return { ...order, quantity: newQuantity, price: newPrice, priceHt: newPriceHt };
          } else {
              // If quantity is 1, remove the item by not including it in the updated array
              localStorage.removeItem(`QuantityInCart_${id}`);
              return null;
          }
      }
      return order;
  }).filter(Boolean); // Remove null entries from the array
  store.dispatch(setOrder({ order: updatedOrders }));
};

  const deletePromo = (promoIndex) => {
    // Make a copy of selectedPromos before modifying it
    let updatedPromo = [...selectedPromos];

    // Check if the promoIndex is valid
    if (promoIndex >= 0 && promoIndex < updatedPromo.length) {
      // Remove the element at the specified index
      updatedPromo.splice(promoIndex, 1);

      // console.log(updatedPromo);

      // Dispatch the action with the updated selectedPromos
      store.dispatch(deletePromos({ selectedPromos: updatedPromo }));
    } else {
      console.error(`Invalid promo index: ${promoIndex}`);
    }
  };

  const deletecart = () => {
    store.dispatch(setOrder({ order: [] }));
    store.dispatch(resetPromo());
  
  // Initialize array to store order IDs
  const productsToDelete = [];


  orders.forEach(order => {
    productsToDelete.push(order.product.id);
  });

productsToDelete.forEach(productId => {
  const quantityKey = `QuantityInCart_${productId}`;
  localStorage.removeItem(quantityKey);
});
  };

  const sumTotalInPromo = (product) => {
    let total = 0;
    for (let j = 0; j < product.products.length; j++) {
      const item = product.products[j];
      // if (apply === "product") {
      //   total += item.price_after_discount + (frais !== null ? frais : product.price * (taux / 100));

      // }
      // else {
      total += item.price_after_discount;
      // }
    }
    // if (apply === "order") {
    //   return total + (frais !== null ? frais : total * (taux / 100));
    // }
    // else {
    return total;
    // }


  };

  const checkout = async () => {
    store.dispatch(setmodelchekoutButton({modelchekoutButton:true}));
    console.log("check set",setmodelchekoutButton);
    if (isLoggedIn) {
      setModalCheckout(true);
      
      setShow(false);
      store.dispatch(setScroll({ scroll:0}));
    } else {
      store.dispatch(setModalPrincipal({ modalPrincipal: true }));
      setShow(false);
    }
  };

  return (
    <>
      <button onClick={handleShow} className="btn ">
        <ShoppingCartIcon style={{ color: "var(--primaryColor)" }} />
      </button>
      <StyledOffCanvas show={show} onHide={handleClose} scroll={true} placement="end">
        <StyledOffCanvasHeader closeButton style={{ display: "block" }}>
          <Offcanvas.Title>
            <h2>{t("Your Order")}</h2>
          </Offcanvas.Title>
          {(orders && orders.length > 0) ||
            (selectedPromos && selectedPromos.length > 0) ? (
            <TotalPrice>
              {/* <h6>
                Total Price: <span className="price-value">{totalPrice}</span>
              </h6> */}
              <CommanderButton onClick={checkout}>
                {t("Checkout ")}
                {/* {parseFloat(totalPrice).toFixed(2)} */}
                {apply === "product" ? (
                  <>
                    {parseFloat(totalPrice).toFixed(2)}
                    {currency}
                  </>
                ) : (
                  <>
                    {(
                      parseFloat(totalPrice) -
                      (frais !== null ? frais : totalPrice * (taux / 100))
                    ).toFixed(2)}
                    {currency}
                    <p style={{ fontSize: "14px", fontWeight: "normal" }}>
                      {" "}
                      + {modeName} fee {currency}
                      {frais !== null
                        ? parseFloat(frais).toFixed(2)
                        : parseFloat(totalPrice * (taux / 100)).toFixed(2)}
                    </p>
                  </>
                )}

              </CommanderButton>
              <ClearCart onClick={deletecart}>
                <DeleteIcon />
              </ClearCart>
            </TotalPrice>
          ) : null}
        </StyledOffCanvasHeader>
        <StyledOffCanvasBody>
          {/* {!(orders && orders.length > 0 && selectedPromos && selectedPromos.length > 0) && <p>No orders in the cart</p>} */}
          {orders &&
            orders.length > 0 &&
            orders.map((order, index) => (
              <OrderItem key={order.product.id}>
                <OrderHeader>
                  <div>
                    <ItemImage
                      src={`${BaseURI}/combined-uploads/${order.product.image}`}
                    ></ItemImage>
                  </div>
                  <ItemQuantity>
                    {order.quantity > 1 ? (
                      <button className="btn" onClick={() => deleteItem(index,order.product.id)}>
                        <RemoveCircleIcon />
                      </button>
                    ) : (
                      <button className="btn" onClick={() => deleteItem(index,order.product.id)}>
                        <DeleteIcon />
                      </button>
                    )}

                    <button className="btn">{order.quantity} </button>
                    <button className="btn " onClick={() => addItem(index,order.product.id)}>
                      <AddCircleIcon />
                    </button>
                  </ItemQuantity>
                </OrderHeader>
                <ItemName>
                  <h5>{`${order.product.name} ${order.product.size !== "S" ? `(${order.product.size})` : ""
                    }`}</h5>
                  <h5>
                  {apply === "product"
                                  ? parseFloat(
                                    order.product.price +
                                    (frais !== null
                                      ? frais
                                      : order.product.price *
                                      (taux / 100))
                                  ).toFixed(2)
                                  : parseFloat(order.product.price).toFixed(
                                    2
                                  )}
                    {currency}
                    
                  </h5>
                </ItemName>

                <ProductPanierDescription>
                  {order.product.description}
                </ProductPanierDescription>
                {order.options.length > 0 ? (
                  <Options>
                    {order.options.map((option, index) => (
                      <div key={index}>
                        {index === 0 ||
                          option.optionGroupeName !==
                          order.options[index - 1].optionGroupeName ? (
                          <OptionsHeading>
                            {option.optionGroupeName}
                          </OptionsHeading>
                        ) : null}
                        <ProductOptions>
                          <div className="flex justify-between">
                            <Option>{option.name}</Option>
                            {option.price_opt > 0 ? (
                              <Option>
                                {parseFloat(option.price_opt).toFixed(2)}
                                {currency}
                                {/* options: selectedPromos[i].products[j].options, */}
                              </Option>
                            ) : null}
                          </div>
                          {option.options.map((opt) => (
                            <div className="flex justify-between">
                              <Option>&nbsp;+{opt.name}</Option>
                              {opt.price > 0 ? (
                                <Option>
                                  {parseFloat(opt.price).toFixed(2)}
                                  {currency}
                                </Option>
                              ) : null}
                            </div>
                          ))}
                        </ProductOptions>
                      </div>
                    ))}

                  </Options>
                ) : null}
                {/* {parseFloat(order.price).toFixed(2)} */}
                {/* {apply === "product" && (
                  <>
                    <ProductOptions>Extra fees</ProductOptions>
                    <div className="flex justify-between">
                      <Option>{modeName} fee </Option>
                      {frais === 0 || taux === 0 ? (
                        <Option>Free</Option>
                      ) : (
                        <Option>
                          {currency}
                          {frais !== null
                            ? frais
                            : parseFloat(order.price).toFixed(2) *
                            (taux / 100)}
                        </Option>
                      )}
                    </div>
                  </>
                )} */}
                <PriceContainer>
                  <h5>Total :</h5>
                  <h5>
                    {parseFloat(order.price).toFixed(2)}
                    {currency}
                    {/* {apply === "product"
                      ? parseFloat(
                        order.price +
                        (frais !== null
                          ? (frais * order.quantity)
                          : order.price * ((taux / 100) * order.quantity))
                      ).toFixed(2)
                      : parseFloat(order.price).toFixed(2)} */}
                  </h5>
                </PriceContainer>
              </OrderItem>
            ))}

          {selectedPromos &&
            selectedPromos.length > 0 &&
            selectedPromos.map((product, ind) => (
              <PromoContainer key={product.promo._id}>
                <PromoDeleteButton>
                  <button onClick={() => deletePromo(ind)}>
                    <DeleteIcon />
                  </button>
                </PromoDeleteButton>
                <PromoTitle key={ind}> {product.promo.name}</PromoTitle>

                {product.products.map((order, index) => (
                  <div key={order.product.id}>
                    <OrderHeader>
                      <div>
                        <ItemImage
                          src={`${BaseURI}/combined-uploads/${order.product.image}`}
                        ></ItemImage>
                      </div>
                      {/* <ItemQuantity>
                        {order.quantity > 1 ? (
                          <button
                            className="btn "
                            onClick={() => deleteItem(index)}
                          >
                            <RemoveCircleIcon />
                          </button>
                        ) : (
                          <button
                            className="btn"
                            onClick={() => deleteItem(index)}
                          >
                            <DeleteIcon />
                          </button>
                        )}
                        <button onClick={() => deletePromo(ind)}>
                          <DeleteIcon />
                        </button>

                        <button className="btn">{order.quantity} </button>
                        <button className="btn " onClick={() => addItem(index)}>
                          <AddCircleIcon />
                        </button>
                      </ItemQuantity> */}
                    </OrderHeader>
                    <ItemName>
                      <h5>{`${order.product.name} ${order.product.size !== "S"
                        ? `(${order.product.size})`
                        : ""
                        }`}</h5>
                      <h5>
                        {order.price_after_discount !== order.price &&
                          product.promo.discount === 100 ? (
                          <span>Free</span>
                        ) : order.price_after_discount !== order.price ? (
                          <>
                            <span
                              style={{
                                textDecorationLine: "line-through",
                                marginRight: "5px",
                                color: "#a6a6a6",
                              }}
                            >
                              {`${parseFloat(
                                order.product.price
                              ).toFixed(2)}${currency}`}
                            </span>
                            <span>
                              {`${parseFloat(
                                order.product.price -
                                (order.product.price *
                                  product.promo.discount) /
                                100
                              ).toFixed(2)}${currency}`}
                            </span>
                          </>
                        ) : (
                          `${parseFloat(order.product.price).toFixed(
                            2
                          )}${currency}`
                        )}
                      </h5>
                    </ItemName>

                    <ProductPanierDescription>
                      {order.product.description}
                    </ProductPanierDescription>
                    {order.options.length > 0 ? (
                      <Options>
                        {order.options.map((option, index) => (
                          <div key={index}>
                            {index === 0 ||
                              option.optionGroupeName !==
                              order.options[index - 1].optionGroupeName ? (
                              <OptionsHeading>
                                {option.optionGroupeName}
                              </OptionsHeading>
                            ) : null}
                            <ProductOptions>
                              <div className="flex justify-between">
                                <Option>{option.name}</Option>
                                {option.price_opt > 0 ? (
                                  <Option>
                                    {parseFloat(option.price_opt).toFixed(2)}
                                    {currency}
                                  </Option>
                                ) : null}
                              </div>
                              {option.options.map((opt) => (
                                <div className="flex justify-between">
                                  <Option>&nbsp;+{opt.name}</Option>
                                  {opt.price > 0 ? (
                                    <Option>
                                      {parseFloat(opt.price).toFixed(2)}
                                      {currency}
                                    </Option>
                                  ) : null}
                                </div>
                              ))}
                            </ProductOptions>
                          </div>
                        ))}
                      </Options>
                    ) : null}
                    {/* {apply === "product" && (
                      <>
                        <ProductOptions>Extra fees</ProductOptions>
                        <div className="flex justify-between">
                          <Option>{modeName} fee </Option>
                          {frais === 0 || taux === 0 ? (
                            <Option>Free</Option>
                          ) : (
                            <Option>
                              {currency}
                              {frais !== null
                                ? frais
                                : parseFloat(order.price).toFixed(2) *
                                (taux / 100)}
                            </Option>
                          )}
                        </div>
                      </>
                    )} */}

                    <PriceContainer>
                      <h6>SubTotal :</h6>
                      <h6>
                        {parseFloat(order.price_after_discount).toFixed(2)}
                        {currency}
                        {/* {apply === "product"
                          ? parseFloat(
                            order.price_after_discount +
                            (frais !== null
                              ? frais
                              : order.price_after_discount * (taux / 100))
                          ).toFixed(2)
                          : parseFloat(order.price_after_discount).toFixed(2)} */}
                      </h6>
                    </PriceContainer>
                    <hr />
                  </div>
                ))}
                <PriceContainer>
                  <h5> Total :</h5>
                  <h5>
                    {/* {console.log(sumTotalInPromo(product))} */}

                    {parseFloat(sumTotalInPromo(product)).toFixed(2)}
                    {currency}
                  </h5>
                </PriceContainer>
              </PromoContainer>
            ))}
        </StyledOffCanvasBody>
      </StyledOffCanvas>
      <Checkout
        modalCheckout={modalCheckout}
        setModalCheckout={setModalCheckout}
        // apply={apply}
        // modeName={modeName}
        // frais={frais}
        // taux={taux}
        sumTotalInPromo={sumTotalInPromo}
        totalPrice={totalPrice}
        totalPriceHt={totalPriceHt}
      />
    </>
  );
}

const StyledOffCanvas = styled(Offcanvas)`
  background-color: #f5f5f5;
  border-radius: 20px 0 0 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  @media (max-width: 768px) {
    background-color: #f5f5f5;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    width: 90% !important;
  }
`;

const StyledOffCanvasHeader = styled(Offcanvas.Header)`
  padding: 20px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  .btn-close {
    position: absolute;
    top: 25px;
    right: 35px;
  }
`;

const StyledOffCanvasBody = styled(Offcanvas.Body)`
  padding: 20px;
  background-color: #e6e6e6;
`;

const PriceValue = styled.span`
  color: var(--primaryColor);
`;

const CommanderButton = styled.button`
  // background-color: var(--primaryColor);
  background: var(--primaryColor);
  color: #fff;
  border: none;
  border-radius: 15px;
  font-size: 18px;
  margin-left: 10px;
  padding: 10px;
  &:hover {
    background-color: var(--primaryColorLight);
  }
`;

/* Order item styles */

const OrderItem = styled.div`
  margin-bottom: 20px;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
`;

const OrderHeader = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

const ItemName = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ItemImage = styled.img`
  width: 150px;
  height: 100px;
  object-fit: contain;
  margin-bottom: 15px;
`;

const ItemQuantity = styled.div`
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  border-radius: 25px;
  height: 40px;
  width: 60%;
  margin-right: 40%;
  margin-bottom: 15px;
`;

const ProductPanierDescription = styled.p`
  font-size: 14px;
  // font-style: italic;
  margin-bottom: 10px;
  color: #666;
`;

const Options = styled.div`
  margin-bottom: 10px;
`;

const ProductOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const OptionsHeading = styled.h6`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Option = styled.p`
  font-size: 14px;
  // font-style: italic;
  margin-bottom: 5px;
  color: #666;
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
`;

const ClearCart = styled.div`
  display: flex;
  align-items: flex-end;
  color: #fff;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  background-color: #dc3545;
  border-color: #dc3545;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  &:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    cursor: pointer;
  }
`;

const TotalPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
  font-weight: bold;
`;

const PromoTitle = styled.h6`
  margin-left: 3px;
  font-size: 20px;
  border-radius: 10px;
  padding: 5px;
  text-align: center;
`;

const PromoContainer = styled.div`
  margin-bottom: 20px;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
`;

const PromoDeleteButton = styled.div`
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 40%;
  border-radius: 25px;
  text-align: center;
  padding: 6px;
  margin-bottom: 10px;
`;
