import styles from "./checklist.module.css";
import { useTranslation } from "react-i18next";
// Importing the validation rules
import {
  UPPERCASE_REGEX,
  NUMBER_REGEX,
  LENGTH_REGEX,
  SPECIAL_CHARS_REGEX
} from "./validationRules";

const rules = [
  { label: "One uppercase", pattern: UPPERCASE_REGEX },
  { label: "One number", pattern: NUMBER_REGEX },
  { label: "Min 8 characters", pattern: LENGTH_REGEX },
  { label: "One special character", pattern: SPECIAL_CHARS_REGEX }
];

// Added missing function keyword
const CheckList = ({value}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      {rules.map((rule,index) => {
        const cn =
          value && value.pwd?.match(rule.pattern) ? styles.passed : "";
        return <p key={index} className={cn} style={{textAlign:"left",fontSize:"16px"}}>{t(rule.label)}</p>;
      })}
    </div>
  );
};

export default CheckList;
