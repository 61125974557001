import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { BaseURI, store } from "../../shared";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ClearIcon from "@mui/icons-material/Clear";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { setScroll } from "../../shared/slice/scroll/ScrollSlice";
import { useTranslation } from "react-i18next";
import {
    setModeDelivery,
    setModesModal,
} from "../../shared/slice/ModalMode/ModalMode";
import { getProductByStoreByMode } from "../../shared/slice/restaurant/RestaurantService";
import {
    setModeId,
    setModeSelectedId,
    setProduct,
} from "../../shared/slice/restaurant/RestaurantSlice";
import { useNavigate } from "react-router-dom";
import { resetPromo } from "../../shared/slice/promos/PromosSlice";
import { setOrder } from "../../shared/slice/order/OrderSlice";
import { disconnects } from "../../shared/slice/auth/AuthSlice";
import { setModelGuest } from "../../shared/slice/ModalLogin/ModalLoginSlice";

export default function ModalModes() {
    const loggedInGuest = useSelector(
        (state) => state.authentification.loggedInGuest
      );
    const { t } = useTranslation();
   
    const modeSelectedId = useSelector(
        (state) => state.restaurant.modeSelectedId
    );
    const restaurantSelected = useSelector(
        (state) => state.restaurant.restaurantSelected
    );
    const products = useSelector((state) => state.restaurant.product);
    const modes = useSelector((state) => state.restaurant.mode);
    const modesModal = useSelector((state) => state.modalMode.modesModal);
    const navigate = useNavigate();

    const [selectedMode, setSelectedMode] = useState(modeSelectedId);
    useEffect(() => {
        if (modesModal) {
            store.dispatch(setScroll({ scroll: 1}));
        }
        
    }, [modesModal]);

    const handleCloseModalModes = () => {
        store.dispatch(setModesModal({ modesModal: false }));
        store.dispatch(setScroll({ scroll: 0}));
        
        // document.documentElement.style.overflow = 'auto';

    };

    const handleModeButtonClick = (mode) => {
        console.log("mode", mode);
        setSelectedMode(mode);
    };

    const handleConfirmationButtonClick = () => {
        const isLivraisonMode =
            modes.find((m) => m.mode._id === selectedMode)?.mode.name === "Delivery";
        if (!selectedMode) return;
        if (selectedMode === modeSelectedId) {
            handleCloseModalModes()
        } else {
            if (isLivraisonMode) {
                if (loggedInGuest){
                    store.dispatch(disconnects());
                    store.dispatch(setModelGuest({ modelGuest: false }));
                }
                store.dispatch(setModeDelivery({ modeDelivery: true }));
            } else {
                getProductByStoreByMode(restaurantSelected._id, selectedMode)
                    .then((res3) => {
                        const productsWithQtyZero = res3.map(product => ({ ...product, QuantityInCart: 0 }))
                        store.dispatch(setProduct({ product: res3 }));
                        store.dispatch(setModeSelectedId({ modeSelectedId: selectedMode }));
                    })
                    .catch((err) => {
                        navigate(`/page404`);
                    });
                handleCloseModalModes();
                // store.dispatch(setOrder({ order: [] }));
                // store.dispatch(resetPromo());
            }
            store.dispatch(setModeId({ modeId: selectedMode }));
            setSelectedMode(null); // Reset selected mode after changing the mode
        }

    };
    const orders = useSelector((state) => state.order.order)
    const orderspromos = useSelector((state) => state.promos.selectedPromos)

    const [isSwitchedOn, setIsSwitchedOn] = useState(false);
    return (
        <StyledModalMode
            isOpen={modesModal}
        // onRequestClose={handleCloseModalModes}
        >

{/*{products&&selectedMode !== null && (
                <ButtonClose onClick={handleCloseModalModes}>
                    <ClearIcon style={{ fontSize: "27px" }} />
                </ButtonClose>
            )}
        */}

            {isSwitchedOn === true ?
                <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                    <div style={{ marginBottom: "2rem", fontSize: "18px" }}>Switching modes will clear your cart.<br />Would you like to proceed?</div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
                        <ButtonStyled onClick={() => {
                            handleConfirmationButtonClick()
                            store.dispatch(resetPromo())
                            store.dispatch(setOrder({ order: [] }))
                            localStorage.clear();
                            setIsSwitchedOn(false)
                        }}>{t('Yes')}</ButtonStyled>
                        <ButtonStyled style={{ marginLeft: "10px" }} onClick={() => setIsSwitchedOn(false)}>{t('No')}</ButtonStyled>
                    </div>
                </div>
                : <div>
                    <h3>{t('Select Your Mode')}</h3>

                    <ButtonGroup>
                        {modes.map((mode) => (
                            <ButtonStyled
                                key={mode.mode._id}
                                onClick={() => handleModeButtonClick(mode.mode._id)}
                                isSelected={selectedMode === mode.mode._id}
                            >
                                {mode.mode.name === "Delivery" ? (
                                    <span>
                                        <DeliveryDiningIcon style={{ fontSize: '30px' }} />
                                    </span>
                                ) : mode.mode.name === "Takeaway" ? (
                                    <span>

                                        <ShoppingBagIcon style={{ fontSize: '30px' }} />
                                    </span>
                                ) : (
                                    <span>
                                        <RestaurantMenuIcon style={{ fontSize: '30px' }} />
                                    </span>
                                )}
                                <h6>

                                    {t(mode.mode.name)}
                                </h6>
                            </ButtonStyled>
                        ))}
                    </ButtonGroup>
                    <ConfirmationButtonStyled onClick={() => {
                        if ((orders.length > 0 || orderspromos.length > 0) && selectedMode !== modeSelectedId) {
                            setIsSwitchedOn(true)
                        } else {
                            handleConfirmationButtonClick()
                        }
                    }}>
                        {t('Confirm')}
                    </ConfirmationButtonStyled></div>}

        </StyledModalMode>
    );
}

function ReactModalAdapter({ className, modalClassName, ...props }) {
    return (
        <Modal className={modalClassName} portalClassName={className} {...props} />
    );
}

const StyledModalMode = styled(ReactModalAdapter).attrs({
    modalClassName: "ModalModes",
    overlayClassName: "OverlayModes",
})`
  .ModalModes {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 15px;
    height: 250px;
    width: 400px;
    text-align: center;
    outline: none;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
  @media (max-width: 768px) {
    .ModalModes {
      width: 95%;
      min-width: 90%;
    }
  }

  .OverlayModes {
    background: rgba(0,0,0,0.3);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
    
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content:space-between;
//   background: #f6f6f6;
  border-radius: 15px;
  margin-bottom: 25px;
  margin-top: 35px;
  
//   height: 41px;
`;
const ButtonStyled = styled.button`
  appearance: none;
  
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px;
  border: 2px solid
    ${({ isSelected }) => (isSelected ? "transparent" : "#f6f6f6")};
  outline: none;
  border-radius: 15px;
  background-color: ${({ isSelected }) =>
        isSelected ? "#eb1700" : "#f6f6f6"};
  color: ${({ isSelected }) => (isSelected ? "#fff" : "#eb1700")};
  font-size: 15px;
  cursor: pointer;
  padding-right: 0px;
  width: 110px;
//   display: flex; 
//   justify-content: space-evenly;
//   align-items: center;
`;


const ConfirmationButtonStyled = styled.button`
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 5px;
  border: 2px solid #eb1700;
  outline: none;
  border-radius: 15px;
  background-color: #eb1700;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  padding-right: 0px;
  width: 100%;
`;

const ButtonClose = styled.button`
  position: absolute;
  top: 5px;
  right: 3px;
  padding-right: 10px;
  cursor: pointer;
  background-color: white;
  margin-top: 2px;
`;  