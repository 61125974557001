import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { BaseURI, store } from "../../shared";
import { useTranslation } from "react-i18next";
import { setScroll } from "../../shared/slice/scroll/ScrollSlice";
import MenuIcon from '@mui/icons-material/Menu';
import i18n from "../../i18n";
import LanguageIcon from "@mui/icons-material/Language";
import Select, { components } from "react-select"
import { useLocation, useNavigate } from "react-router-dom";
import { disconnect } from "../../shared/slice/auth/AuthSlice";
import HistoryIcon from '@mui/icons-material/History';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ModelGuest from "../Modals/ModelGuest";
import { setModelGuest } from "../../shared/slice/ModalLogin/ModalLoginSlice";
import { deleteOrderNotification } from "../../shared/slice/order/OrderSlice";
import { setNotification } from "../../shared/slice/ModalNotification/ModalNotification";


export default function SideNavBar() {
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language.split('-')[0]);
    const isLoggedIn = useSelector((state) => state.authentification.isLoggedIn);
    const selectedRestaurant = useSelector((state) => state.restaurant?.restaurantSelected?._id);
    const loggedInGuest = useSelector((state) => state.authentification.loggedInGuest);
    const { t } = useTranslation();
    const scroll = useSelector((state) => state.scroll.scroll);
    const [show, setShow] = useState(false);
    const [selectOption, setSelectOption] = useState(null)
    const handleClose = () => {
        setShow(false);
        store.dispatch(setScroll({ scroll: 0}));
    }
    const handleShow = () => setShow(true);
    const user = useSelector((state) => state.authentification.loggedInUser);
    const users= useSelector((state) => state.authentification.loggedInGuest);


 
    const logout = () => {
        // window.location.reload();
        store.dispatch(disconnect());
        // navigate(`/select-store/${selectedRestaurant}`);
        store.dispatch(setModelGuest({ ModelGuest: false }));
        // store.dispatch(deleteOrderNotification());
        store.dispatch(setNotification({ Notification:[]}))
              
    };

    const handleChange = (e) => {
        const selectedLanguage = e.target.value;
        i18n.changeLanguage(selectedLanguage);
        setSelectedLanguage(selectedLanguage);
    };
    const navigate = useNavigate();

    const handleNavToordershistory= () => {
                   navigate(`/ordershistory/${selectedRestaurant}`);
    };
    const handleNavTofavoritesorders= () => {
        navigate(`/favoritesorders`);
};


useEffect(() => {
  if (show) {
    store.dispatch(setScroll({ scroll: 1}));
  }else {
    store.dispatch(setScroll({ scroll: 0 }));
  }
}, [show]);


    return (
        <>
            <button onClick={handleShow} className="btn ">
                <MenuIcon style={{ color: "var(--primaryColor)" }} />
            </button>
            <StyledOffCanvas show={show} onHide={handleClose} scroll={true} placement="end" style={{width:"20%" }} >
                <StyledOffCanvasHeader closeButton style={{ display: "block" }}>
                    <Offcanvas.Title>
                        {isLoggedIn ? (
                            <div style={{ display: "flex" }}  >  <ManageAccountsIcon style={{ color: "var(--primaryColor)", width: "32px", height: "32px", marginTop: "4px" }} /> <LisTm> {user?.firstName ? user.firstName : users?.firstName}  </LisTm> </div>
                        ) : (<h2>{t("Menu")}</h2>)}

                    </Offcanvas.Title>

                </StyledOffCanvasHeader>
                <StyledOffCanvasBody>

                <div style={{ display: "flex",alignItems:"center" }} > 
                 
                        <LanguageIcon style={{ color: "#eb1700", width: "24px", height: "24px", marginTop: "-6px" }} />
                        <SelectorStyled onChange={(e) => handleChange(e)} defaultValue={selectedLanguage} >
                            <option value="en">English</option>
                            <option value="es">Espanol </option>
                            <option value="it">Italian</option>
                            <option value="fr">Francais</option>
                        </SelectorStyled>
         
  
                </div>
                    {isLoggedIn && <>
                    {loggedInGuest===undefined &&                         <div style={{ display: "flex",alignItems:"center" }} onClick={handleNavToordershistory}>  <HistoryIcon style={{ color: "var(--primaryColor)", width: "24px", height: "24px", marginTop: "4px" }} /> <LisTm> History   </LisTm> </div>
                  }
                        <div style={{ display: "flex",alignItems:"center" ,paddingTop:"4px" }} onClick={logout}>  <LogoutIcon style={{ color: "var(--primaryColor)", width: "24px", height: "24px", marginTop: "4px" }} /> <LisTm> Logout  </LisTm> </div>
                       
                    </>

                    }
                </StyledOffCanvasBody>
            </StyledOffCanvas>

        </>
    );
}

const StyledOffCanvas = styled(Offcanvas)`
  background-color: #f5f5f5;
  border-radius: 20px 0 0 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  @media (max-width: 768px) {
    background-color: #f5f5f5;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    width: 50% !important;
  }
`;

const StyledOffCanvasHeader = styled(Offcanvas.Header)`
  padding: 20px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  .btn-close {
    position: absolute;
    top: 25px;
    right: 35px;
  }
`;

const StyledOffCanvasBody = styled(Offcanvas.Body)`
  padding: 20px;
  background-color: #e6e6e6;
`;
const LanguageButton = styled.button`

`
const SelectorStyled = styled.select`

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-left: 3px;
  // border: 2px solid #eb1700;
  outline: none;
  border-radius: 15px;
  color: var(--primaryColor);
  font-size: 1.3rem;
  cursor: pointer;
  background-color: rgba(
    var(--bs-tertiary-bg-rgb),
    var(--bs-bg-opacity)
  ) !important;
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
const LisTm = styled.div`
appearance: none;
-webkit-appearance: none;
-moz-appearance: none;
padding-left: 3px;
// border: 2px solid #eb1700;
outline: none;
border-radius: 15px;
color: var(--primaryColor);
font-size: 1.3rem;
cursor: pointer;
background-color: rgba(
  var(--bs-tertiary-bg-rgb),
  var(--bs-bg-opacity)
) !important;
@media (max-width: 768px) {
  font-size: 0.8rem;
}
`;
