import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { BaseURI } from "../../shared";
import styled from "styled-components";
import tw from "twin.macro";
import getSymbolFromCurrency from "currency-symbol-map";
import ProductModal from "./productModal/ProductModal";
import Promo from "../Promo/Promo";
import { PromoImage } from "../../assets/images/exports";
import { useTranslation } from "react-i18next";
import { Badge } from "@mui/material";
import Productimg from "./Productimg";
// import "./menu.css"
export default function Menu() {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState({
    product: undefined,
    open: false,
  });
  const ref = useRef(null);
  const SecondScroll = useRef(null);

  const menu = useSelector((state) => state.restaurant.menu);
  const promo = useSelector((state) => state.promos.promos);
  const categories = menu?.categorys;
  const products = useSelector((state) => state.restaurant.product);

  const frais = useSelector((state) => state.restaurant.frais);
  const taux = useSelector((state) => state.restaurant.taux);
  const apply = useSelector((state) => state.restaurant.apply);
  const defaultCategoryId = categories && categories.length > 0 ? categories[0]._id : null;

  const [Closes, setClosestCategory] = useState(defaultCategoryId);


  // console.log("taux", taux);
  // console.log("frais", frais);
  // console.log("apply", apply);
  // get the first modal

  // const handleProductClick = (productId) => {
  //   console.log(menu)

  const handleProductClick = (productId) => {
    const product = products.find((product) => product._id === productId);
    setOpenModal({
      product: product,
      open: true,
    });
  };

  function scrollToElement(id) {
    const element = document.getElementById(id);

    if (element) {
      let offset;

      if (window.innerWidth < 1023) {
        // For mobile devices, consider both navbar and sidebar height
        offset = element.offsetTop - (56 + 73.5 + 75);
      } else {
        // For desktop devices, consider only navbar height
        offset = element.offsetTop - (73.5 + 75);
      }

      window.scrollTo({
        top: offset,
        behavior: "instant",
      });
    }
  }


  useEffect(() => {
    const handleScroll = () => {

      const filteredCategories = categories.filter(category => {
        return category.availability && category.availabilitys.some(avail => {
          return avail.mode === modesFinal && avail.availability;
        });
      });
      const scrolledToBottom = window.innerHeight + document.documentElement.scrollTop + 2 > document.documentElement.offsetHeight;
      if ((document.documentElement.scrollTop < 250) && (promo[0] != null)) {
        setClosestCategory(promo[0]?._id);
      }

      else if (scrolledToBottom) {
        setClosestCategory(filteredCategories[filteredCategories.length - 1]?._id);
      }
      else {
        const categoryElements = filteredCategories.map(category => document.getElementById(category._id));
  
        // Calculate distances from the center of the viewport
        const distances = categoryElements.map(element => {
          const rect = element.getBoundingClientRect();
          if (window.innerWidth < 1023){
            const centerY = (window.innerHeight / 2  )+150; // Center of the viewport
            const elementCenterY = (rect.top + rect.height / 2); // Center of the element
            return Math.abs(centerY - elementCenterY);
          }else{  const centerY = (window.innerHeight / 2  ); // Center of the viewport
          const elementCenterY = (rect.top + rect.height / 2); // Center of the element
          return Math.abs(centerY - elementCenterY);}
        });
        
        const minDistance = Math.min(...distances);
        const closestIndex = distances.findIndex(distance => distance === minDistance);
        setClosestCategory(filteredCategories[closestIndex]?._id);

      }
        if (window.innerWidth < 1023) {
          const scrollTop = document.documentElement.scrollTop;
          const documentWidth = document.documentElement.offsetHeight;
          const secondScrollWidth = SecondScroll.current.scrollWidth;


          const desiredScrollPosition = (scrollTop / documentWidth) * secondScrollWidth +50;
          const thumbCenter = desiredScrollPosition - (SecondScroll.current.offsetHeight / 2);
          SecondScroll.current.scrollTo(thumbCenter, 0);
        } else {
          const scrollTop = document.documentElement.scrollTop;
          const documentHeight = document.documentElement.offsetHeight;
          const secondScrollHeight = SecondScroll.current.offsetHeight;


          const desiredScrollPosition = (scrollTop / documentHeight) * secondScrollHeight;
          const thumbCenter = desiredScrollPosition - (SecondScroll.current.offsetHeight / 2);
          // For desktop devices, consider only navbar height
          SecondScroll.current.scrollTo(0, thumbCenter);
        }

      
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [categories, promo]);

  const modesFinal = useSelector((state) => state.restaurant.modeSelectedId);

  const productId = "65a01b480f8293fb98ac2d8a";

  // Now, construct the key for local storage
  const quantityKey = `QuantityInCart_${productId}`;

  // Retrieve the quantity from local storage
  const quantityInCart = localStorage.getItem(quantityKey);

  return (
    categories &&
    products && (
      <HomeConatainer ref={ref}>
        <ContainerCategories ref={SecondScroll}>

          {promo.length > 0 && (
            <CategoryWrapper onClick={() => scrollToElement(promo[0]?._id)}
              // style={{ background: Closes === promo[0]._id ? "var(--primaryColor) " : null  }} 
              style={{ borderBottom: Closes === promo[0]._id ? "solid 3px var(--primaryColor)" : null }}
            >
              <CategoryImage src={PromoImage} />
              <CategoryName style={{ color: Closes === promo[0]._id ? "var(--primaryColor)" : null }}  >{t("Our promos")}</CategoryName>
            </CategoryWrapper>
          )}
          {categories.map((category, index) => {
            return (
              category.availability && category.availabilitys.map((avail, index) => {
                if (avail.mode === modesFinal && avail.availability) {
                  return (
                    <CategoryWrapper
                      key={index}
                      onClick={() => scrollToElement(category._id)}
                      style={{
                        borderBottom: Closes === category._id ? "solid 3px var(--primaryColor)" : null
                      }}>
                      <CategoryImage src={`${BaseURI}/combined-uploads/${category.image}`} />
                      <CategoryName
                        style={{
                          color: Closes === category._id ? "var(--primaryColor)" : null,
                          fontFamily: Closes === category._id ? "QuicksandBold" : null
                        }}>
                        {category.name}
                      </CategoryName>
                    </CategoryWrapper>
                  );
                }
                return null; // Added to handle the case when the condition is not met
              })
            );
          })}

        </ContainerCategories>
        <AllProducts>
          <Promo />
          <div>
            {categories.map((category) => (
              category.availability && category.availabilitys.map((avail) => {
                if (avail.mode === modesFinal && avail.availability) {
                  return (
                    <div key={category._id} id={category._id}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "95%",
                        }}
                      >
                        <Line />{" "}
                        <ProductCategories>{category.name}</ProductCategories>
                        <Line />
                      </div>
                      <ProductDetails>
                        {/* affichage product */}
                        {products.map((product) => {
                          if (
                            category._id === product.category &&
                            product.availabilitys.length > 0 &&
                            product.availabilitys[0].availability &&
                            product.availability
                          ) {

                            return (
                              // <Badge  badgeContent={quantityInCart} 
                              // color="primary" 
                             
                              // anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}> 

                              <ProductCard
                                key={product._id}
                                onClick={() => handleProductClick(product._id)}
                              >

                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  {(() => {
                                    const productId = product._id;
                                    const quantityKey = `QuantityInCart_${productId}`;
                                    const quantityInCart = localStorage.getItem(quantityKey);

                                    return (
                                      quantityInCart && parseInt(quantityInCart) > 0 && (
                                        <div >
                                          <CardBadge>{parseInt(quantityInCart)}</CardBadge>
                                        </div>
                                      )
                                    );
                                  })()}
                                </div>
                                {/* <Productimg src={product.image} alt={product.name} /> */}
                                <ProductImage
                                  src={`${BaseURI}/combined-uploads/${product.image}`}
                                  alt={product.name}
                                  loading="lazy"
                                />
                                <Wrapper>
                                  <StyledContent>
                                    <ProductTitle>{product.name}</ProductTitle>
                                    <div style={{margin:"3px 0px"}}>
                                    <ProductPrice>
                                      {product.size.length > 1 && "From "}
                                      {apply === "product"
                                        ? parseFloat(
                                          product.size[0].price +
                                          (frais !== null
                                            ? frais
                                            : product.size[0].price * (taux / 100))
                                        ).toFixed(2)
                                        : parseFloat(product.size[0].price).toFixed(2)}{" "}
                                      {getSymbolFromCurrency(menu.currency)}
                                    </ProductPrice>
                                  </div>
                                    <ProductDescription>
                                      {window.innerWidth > 768
                                        ? product.description.length > 120
                                          ? `${product.description.substring(
                                            0,
                                            120
                                          )}...`
                                          : product.description
                                        : product.description.length > 40
                                          ? `${product.description.substring(0, 40)}...`
                                          : product.description}
                                    </ProductDescription>
                                  </StyledContent>
                             

                                </Wrapper>
                              </ProductCard>
                           
                            );
                          } else {
                            return null;
                          }
                        })}
                      </ProductDetails>
                    </div>
                  );
                } else {
                  return null;
                }
              })
            ))}
          </div>

        </AllProducts>
        {openModal.open && (
          <ProductModal openModal={openModal} setOpenModal={setOpenModal} />
        )}
      </HomeConatainer>
    )
  );
}

const CardBadge = styled.h6`

min-width: 30px;
min-height: 30px;
  background-color: var(--primaryColor);
  border-radius: 50%;
  
  position: absolute;
  margin-top: -0.8rem;
    margin-left: -1rem;


  color: #fff;
 
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
`;
const HomeConatainer = styled.div`
  display: flex;
  margin-left:2%;
  @media (max-width: 768px) {
    display:block;
    margin-left: 0;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    display:block;
    margin-left: 0;
`;
//sidebar
const ContainerCategories = styled.div`
  z-index: 1001;
  
  width: 20%;
  margin-right: 5%;
  overflow-y: auto; /* Add vertical scrollbar if necessary */
  max-height: calc(100vh - 127px); /* Subtract the height of the navbar */
  position: sticky;
  top: 13%;
  scrollbar-width: none;

  // border:1px solid;
  @media (min-width: 1024px) {
    max-width: 250px;
  }

  @media (max-width: 768px) {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    overflow-x: auto; 
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    width: 100%;
    overflow-x: auto;
    position: sticky;
    top: 127px;
    background: #fff;
    z-index: 2;
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    width: 100%;
    overflow-x: auto;
    position: sticky;
    top: 120px;
    background: #fff;
    z-index: 2;
  }
  position: sticky;
  top: 13%;

  @media (max-width: 768px) {
   display:flex;
   border-bottom:1px solid rgba(0, 0, 0, 0.1);
 
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    
    display:flex;
`;

const StickyDiv = styled.div`
  position: sticky;
  top: 13%;

  @media (max-width: 768px) {
   display:flex;
   border-bottom:1px solid rgba(0, 0, 0, 0.1);
 
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    
    display:flex;
`;

const CategoryName = styled.p`
  font-weight: bold;
  font-size: 18px;
  text-transform: capitalize;
  color: #000;
  cursor: pointer;
user-select:none
`;

const CategoryWrapper = styled.a`


  display: flex;
  align-items: center;
  padding: 5px 15px 5px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  text-decoration: none;
  cursor: pointer;
  @media (max-width: 768px) {
    border-top: solid 3px lightgray;
    width: 100%;
    justify-content: center;
    padding-right: 30px;
    padding-left: 30px;
    border-bottom: none;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    border-bottom: none;
    justify-content: center;
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    border-bottom: none;

  }

  
`;

const CategoryImage = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-right: 10px;
`;

//products

const AllProducts = styled.div`
  width: 80%;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 100%;
  }
`;
const ProductCategories = styled.h2`
  text-transform: capitalize;
  font-size: 36px;
  margin: 40px 20px 20px 20px;
  text-align: center;
  display: inline-block;
  @media (max-width: 768px) {
    font-size: 25px;
  }
`;

const ProductDetails = styled.div`
  ${tw`text-center font-bold `}
  display:flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    margin-left: 3%;
  }
`;
const ProductCard = styled.div`
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  width: 250px;
  height: auto;

  
  padding: 1%;
  margin: 15px;
  border-radius: 10px;
  @media (max-width: 768px) {
    width: 43%;
    margin: 10px;
  }
  &:hover {
    background-color: #f6f6f6;
    cursor: pointer;
  }
`;

const ProductImage = styled.img`
  object-fit: contain;
  display: inline;
  
  margin: 0px 0px 10px 0px;
  
  height: auto;
  min-height: 9rem;
  max-height: 9rem;

  @media (max-width: 768px) {
    height: auto;
  }
`;

const StyledContent = styled.div`
  // ${tw`py-4`}
  padding-top: 10px;
  @media (max-width: 768px) {
    padding-top: 0;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const ProductTitle = styled.div`
  ${tw`capitalize`}
  font-size:24px;
  font-weight: 900;
  height: auto;
  min-height:40px;
  overflow-wrap: break-word;
  @media (max-width: 768px) {
    font-size: 18px;
    height: auto;
  }
`;

const ProductDescription = styled.p`
text-align: center;
color: black;
flex-grow: 4;
f
  // ${tw`text-gray-700 text-sm text-left`}
  ${tw`text-left`}
  width: 100%;
  height: auto;
  max-width: 300px; /* Adjust the value as needed */
  word-wrap: break-word;
  // font-style: italic;
 
  padding-left: 2%;
  margin-top: 10px;
  margin-bottom: 5px!important;
  font-size: 14px;
  font-family: QuickSandLight;
  overflow-wrap: break-word;
  @media (max-width: 768px) {
    font-size: 0.7rem;
    // height:auto;
  }
`;

const ProductPrice = styled.p`
  // float: right;
  margin : 3px 0px ; 
 font-size: 1.1rem;
 color:#6c6c6c;
  
font-family: QuickSandMedium;
  // font-style: italic;
  @media (max-width: 768px) {
  font-size: 1rem;
  }
`;

const ProductCardDisabled = styled.div`
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 1%;
  width: 200px;
  height: 275px;
  margin: 15px;
  @media (max-width: 768px) {
    width: 43%;
    margin: 10px;
    height: auto;
  }
  &:hover {
    background-color: #f6f6f6;
    cursor: pointer;
  }
`;
const SoldTag = styled.div`
  position: relative;
  top: 10px;
  right: 10px;
  background-color: #f00;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  z-index: 1;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 12px;
`;
const ProductImagedisabled = styled.img`
  object-fit: contain;
  height: 150px;
  opacity: 0.6;
  pointer-events: none;
  box-shadow: none;
  filter: grayscale(100%);
  transition: opacity 0.3s ease-in-out;
  color: #888;
  cursor: not-allowed;
  pointer-events: none;
  width: 100%;
`;

const Line = styled.div`
  // display: inline-block;
  border-bottom: 3px solid #cccccc;
  width: 40%;
  margin-left: 15px;
  margin-top: 30px;
  @media (max-width: 768px) {
    width: 25%;
  }
`;
