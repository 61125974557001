import React, { useState, useEffect } from 'react';
import styled from "styled-components";
import { BaseURI } from '../../shared';

const Productimg = ({ src, alt }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    // Check if the image is cached in the browser
    caches.open('image-cache').then(cache => {
      cache.match(src).then(cachedResponse => {
        if (cachedResponse) {
          cachedResponse.blob().then(blob => {
            const objectURL = URL.createObjectURL(blob);
            setImageSrc(objectURL);
            console.log(src, "from cache");
          });
        } else {
          // If not cached, fetch the image
          fetch(`${BaseURI}/${src}`)
            .then(response => {
              // Cache the image in the browser
              cache.put(src, response.clone());
              return response.blob();
            })
            .then(blob => {
              const objectURL = URL.createObjectURL(blob);
              setImageSrc(objectURL);
            })
            .catch(error => console.error('Error fetching image:', error));
        }
      });
    });
  }, [src]);

  return (
    <ProductImage
      src={imageSrc}
      alt={alt}
      loading="lazy"
    />
  );
};

const ProductImage = styled.img`
  object-fit: contain;
  display: inline;
  
  margin: 0px 0px 10px 0px;
  
  height: auto;
  min-height: 9rem;
  max-height: 9rem;

  @media (max-width: 768px) {
    height: auto;
  }
`;
export default Productimg;
