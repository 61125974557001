import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InformationStore from "../../../../components/Menu/InformationStore";
import {
    setApply,
    setFrais,
    setMenu,
    setMode,
    setModeName,
    setModeSelectedId,
    setProduct,
    setRestaurantSelected,
    setTaux,
} from "../../../../shared/slice/restaurant/RestaurantSlice";
import {
    getMenuByStore,
    getMode,
    getModeConsomation,
    getProductByStoreByMode,
    getStoreById,
} from "../../../../shared/slice/restaurant/RestaurantService";
import Menu from "../../../../components/Menu/Menu";
import MyNavBar from "../../../../components/Navbar/MyNavBar";
import StoreNavbar from "../../../../components/StoreNavbar/StoreNavbar";
import { lighten } from "polished";
import ModalModes from "../../../../components/Modals/ModalModes";
import ModalStores from "../../../../components/Modals/ModalStores";
import { setModeDelivery, setModesModal } from "../../../../shared/slice/ModalMode/ModalMode";
import { store } from "../../../../shared";
import { setScroll } from "../../../../shared/slice/scroll/ScrollSlice";
export default function SelectStore() {

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const restaurantSelected = useSelector(
        (state) => state.restaurant.restaurantSelected
    );
    const modeSelectedId = useSelector(
        (state) => state.restaurant.modeSelectedId
    );

    setModesModal(true)
    // const menu = useSelector((state) => state.restaurant.menu);
    // const products = useSelector((state) => state.restaurant.product)
    // const modeSelectedId = useSelector((state) => state.restaurant.modeSelectedId)
    // console.log(modeSelectedId);
    // console.log(products);
    // console.log("restaurantSelected", restaurantSelected);
    // console.log(restaurantSelected);

    useEffect(() => {
        const getmode = async () => {
            if (modeSelectedId) {
                const mode = await getMode(modeSelectedId);
                if (mode.mode.applyTaux) {
                    dispatch(setTaux({ taux: mode.mode.taux }));
                } else {
                    dispatch(setFrais({ frais: mode.mode.frais }));
                }
                dispatch(setApply({ apply: mode.mode.applicationType }))
                dispatch(setModeName({ modeName: mode.mode.name }))
            }
        };
        getmode();
    }, [modeSelectedId, restaurantSelected?._id]);
    const scroll = useSelector((state) => state.scroll.scroll);

 
    

    useEffect(() => {
        const fetchedStoresById = async () => {
            //document.documentElement.style.overflow = 'hidden';
            store.dispatch(setModeDelivery({ modeDelivery: false }));

            store.dispatch(setModesModal({ modesModal: true }));
            getStoreById(id)
                .then(async (res) => {
                    console.log("restaurantSelected :", res)
                    dispatch(setRestaurantSelected({ restaurantSelected: res }));
                    // dispatch(setModeSelectedId({ modeSelectedId: res.defaultMode }))
                    dispatch(setModeSelectedId({ modeSelectedId: modeSelectedId }));
                    document.documentElement.style.setProperty(
                        "--primaryColor",
                        res.primairecolor
                    );
                    document.documentElement.style.setProperty(
                        "--primaryColorLight",
                        lighten("0.3", res.primairecolor)
                    );

                    getMenuByStore(id)
                        .then((res2) => {
                            dispatch(setMenu({ menu: res2 }));

                        })
                        .catch((err) => {
                            console.log("Page not found");
                            navigate(`/page404`);
                        });
                    getModeConsomation(id)
                        .then((res4) => {
                            dispatch(setMode({ mode: res4.consumationModes }));
                            // console.log(res4.consumationModes);
                        })
                        .catch((err) => {
                            console.log("Page not found");
                            navigate(`/page404`);
                        });
                    // await getProductByStoreByMode(res._id, res.defaultMode)
                    //     .then((res3) => {
                    //         const productsWithQtyZero = res3.map(product => ({ ...product, QuantyI: 0 }))
                    //         dispatch(setProduct({ product: res3}));
                    //     })
                    //     .catch((err) => {
                    //         console.log("Page not found");
                    //         navigate(`/page404`);
                    //     });
                })
                .catch((err) => {
                    console.log("Page not found");
                    navigate(`/page404`);
                });
        };

        fetchedStoresById();
    }, []);

    return (
        restaurantSelected && (
            <div>
                <div style={{  position: "sticky",
                    top: "0",
                    zIndex: "1005",
                    backgroundColor: "white"
                }}>
                <MyNavBar />
                <ModalModes />
                <StoreNavbar />
                <ModalStores />
                </div>
                <Menu />
            </div>
        )
    );
}
