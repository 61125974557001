// import { createGlobalStyle } from 'styled-components';
// import { QuicksandBold } from '../assets/fonts/static/exports';

// export const GlobalStyle = createGlobalStyle`
//   @font-face {
//     font-family: 'QuicksandBold';
//     src: url(${QuicksandBold}) format('truetype');
//     font-weight: normal;
//     font-style: normal;
//   }
// `;

// reformulate items in order schema 
export function reformulateItems(items) {
    let data = []
    for (let i = 0; i < items.length; i++) {
        const optionsGroup = []
        for (let j = 0; j < items[i].options.length; j++) {
            const option = {
                _id: items[i].options[j]._id,
                id: items[i].options[j].id,
                name: items[i].options[j].name,
                price_opt: items[i].options[j].price_opt,
                options: items[i].options[j].options,
                tax: items[i].options[j].tax,
                quantity: items[i].options[j].quantity,
            }
            const idExistsIndex = optionsGroup.findIndex(item => item.optionGroupeId === items[i].options[j].optionGroupeId);
            if (idExistsIndex === -1) {
                optionsGroup.push({
                    optionGroupeId: items[i].options[j].optionGroupeId,
                    optionGroupeName: items[i].options[j].optionGroupeName,
                    options: [option]
                });
            } else {
                optionsGroup[idExistsIndex].options.push(option);
            }
        }
        // console.log("tax,",items[i].tax);
        data.push({
            image: items[i].image,
            item_price: items[i].item_price,
            id: items[i].id,
            _id: items[i]._id,
            name: items[i].name,
            description: items[i].description,
            // i add this to rebuild object in history and send it to cart another time
            price: items[i].price,

            size: items[i].size,
            quantity: items[i].quantity,
            tax: items[i].tax,
            optionsGroup: optionsGroup
        })
    }
    return data
}

export function reformulatePromo(promo) {
    let promos = []
    for (let k = 0; k < promo.length; k++) {
        let items = promo[k].items
        let data = []
        for (let i = 0; i < items.length; i++) {
            const optionsGroup = []
            for (let j = 0; j < items[i].options.length; j++) {
                const option = {
                    _id: items[i].options[j]._id,
                    id: items[i].options[j].id,
                    name: items[i].options[j].name,
                    price_opt: items[i].options[j].price_opt,
                    options: items[i].options[j].options,
                    tax: items[i].options[j].tax,
                    quantity: items[i].options[j].quantity,
                }
                const idExistsIndex = optionsGroup.findIndex(item => item.optionGroupeId === items[i].options[j].optionGroupeId);
                if (idExistsIndex === -1) {
                    optionsGroup.push({
                        optionGroupeId: items[i].options[j].optionGroupeId,
                        optionGroupeName: items[i].options[j].optionGroupeName,
                        options: [option]
                    });
                } else {
                    optionsGroup[idExistsIndex].options.push(option);
                }
            }
            data.push({
                item_price: items[i].item_price,
                subtotal: items[i].subtotal,
                price_after_discount: items[i].price_after_discount,
                price: items[i].price,

                image: items[i].image,
                id: items[i].id,
                _id: items[i]._id,
                name: items[i].name,
                description: items[i].description,
                size: items[i].size,
                quantity: items[i].quantity,
                tax: items[i].tax,
                optionsGroup: optionsGroup
            })
        }
        promos.push({ "items": data, "promo": promo[k].promoId })

    }

    return promos
}
