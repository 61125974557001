import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Modal from "react-modal";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./shared/index.js";
// import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';
import { StrictMode } from 'react';
Modal.setAppElement("#root");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  
  <Provider store={store}>
    {/* <ClearCacheProvider duration={5000}> */}

    <PersistGate persistor={persistor}>
      {/* <React.StrictMode> */}
      {/* <StrictMode> */}

      <App />
      {/* </StrictMode> */}
      {/* </React.StrictMode> */}
    </PersistGate>
    {/* </ClearCacheProvider> */}
  </Provider>
);

reportWebVitals();

