import { useSelector } from 'react-redux';
import './App.css';
import { FavoritesOrders, Home, OrdersHistory, Page404, StoreScreen, VerifyEmail } from './screens/exports';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate, BrowserRouter, Route, Routes } from 'react-router-dom';
import { Loader } from './components/exports';
import SelectStore from './screens/Home/StoreScreen/SelectStore/SelectStore';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { BaseURI, store } from './shared';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // Import the i18n instance
import { setOrderNotification } from './shared/slice/order/OrderSlice';
import * as Sentry from "@sentry/react";
import FirstPage from './screens/FirstPage';
import { postNotification, putNotificationservice } from './shared/slice/ModalNotification/NotificationService';
import { setNotification } from './shared/slice/ModalNotification/ModalNotification';
import messageSound from "./assets/son/Message-Iphone.mp3"
import Dialog from '@mui/material/Dialog';
import QrCode from 'react-qr-code';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import styled from "styled-components";
import Offcanvas from "react-bootstrap/Offcanvas";
import ResetPassword from './components/Modals/ResetPassword';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setIsKioskLikely } from './shared/slice/ModalLogin/ModalLoginSlice';

import { io } from "socket.io-client"

// Sentry.init({
//   dsn: "https://858e4ac74a66d2dc9316e4cb8253f75d@o4506870132310016.ingest.us.sentry.io/4506874669170688",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   tracesSampleRate: 1.0,
//   tracePropagationTargets: ["https://demo.eatorder.fr/"],
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });
let socket = null

const stripePromise = loadStripe('pk_test_51OdqTeD443RzoOO5mes3OXIh1OYR1As9gqCOgxiveevF6EaGKVsFfIvMU3kj9OeUcEPKfwnV6sXLLEJe74E5QtX300l7sLfadf');
function App() {
  window.onload = function () {
    console.log("Popup loaded, sending message to parent...");
    window?.opener?.postMessage({ status: 'loaded', url: window.location.href }, "*");
  };


  const isLoading = useSelector((state) => state.root.isLoading);
  const scroll = useSelector((state) => state.scroll.scroll);
  console.log("scroll", scroll);
  const loggedInUser = useSelector((state) => state.authentification.loggedInUser);
  const [openDialog, setOpenDialog] = useState(false);
  const [idorder, setIdOrder] = useState();
  const [statusmessage, setStatusMessage] = useState();
  const loggedInGuest = useSelector((state) => state.authentification.loggedInGuest);
  useEffect(() => {
    const checkKiosk = () => {

      const isTouchDevice = navigator.maxTouchPoints > 0;
      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const fixedScreenRatio = (screenHeight / screenWidth) >= 1.5 && (screenHeight > 1500);

      store.dispatch(setIsKioskLikely({ IsKioskLikely: isTouchDevice && fixedScreenRatio }));

    };



    checkKiosk();

  }, []);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  useEffect(() => {
    if (scroll === 0) {
      document.body.style.overflow = "auto";
      

    } else {
      document.body.style.overflow = "hidden";

    }
  }, [scroll]);
  useEffect(() => {
    if (isLoading === true) {
      document.body.style.overflow = "hidden"

    } else {
      document.body.style.overflow = "auto"

    }
  }, [isLoading])


  useEffect(() => {
    console.log("loggedInGuest.............", loggedInGuest);
    if (loggedInUser || loggedInGuest) {
      socket = io(BaseURI)
    }
  }, [loggedInUser, loggedInGuest])

  useEffect(() => {
    if (socket !== null) {
      console.log("aaa");

      // Event listener for socket connection
      const connectListener = () => {

        console.log("Socket connected, id:", socket);
        console.log(loggedInUser?._id.toString());

        // join room to receive orders
        if (loggedInUser) {
          socket.emit("join_room_orders", loggedInUser?._id.toString())
        } else {
          socket.emit("join_room_orders", loggedInGuest?._id.toString())
        }


      };

      const receiverorders = async (data) => {
        data = data.data
        console.log("socket order : ", data);

        if (loggedInUser || loggedInGuest) {
          const userId = loggedInUser ? loggedInUser._id : loggedInGuest._id;
          try {
            if (JSON.parse(data) instanceof Object) {
              console.log(data);
              store.dispatch(setOrderNotification({ OrderNotification: data }));
              const eventData = JSON.parse(data);
              const messages = eventData.status;
              const sound = new Audio(messageSound);
              if (messages === "rejected" || messages === "missed" || messages === "accepted") {
                try {
                  const resp = await putNotificationservice(eventData.orderId, eventData.status, userId);
                  store.dispatch(setNotification({ Notification: resp.notification }));
                  await sound.play();
                } catch (error) {
                  console.error('Error:', error);
                }
              } else if (messages === "ready") {
                setOpenDialog(true);
                setIdOrder(eventData.orderId);
                setStatusMessage(eventData.status);
                try {
                  const resp = await putNotificationservice(eventData.orderId, eventData.status, userId);
                  store.dispatch(setNotification({ Notification: resp.notification }));
                  await sound.play();
                } catch (error) {
                  console.error('Error:', error);
                }
              }


            }
          } catch (err) {
            console.log(err);
          }
        };

      }
      // orders socket
      socket.on("receive_orders", receiverorders)

      // Add event listener for "connect"
      socket.on("connect", connectListener);



      // Event listener for socket disconnection
      const disconnectListener = () => {
        console.log("Socket disconnected");
      };

      // Add event listener for "disconnect"
      socket.on("disconnect", disconnectListener);

      // Clean up event listeners on component unmount
      return () => {
        socket.off("connect", connectListener);
        socket.off("disconnect", disconnectListener);
        socket.off("receive_orders", receiverorders)
      };
    }
  }, [socket]);

  /*---------------------------sse begin------------------------ */
  // useEffect(() => {
  //   if (loggedInUser || loggedInGuest) {
  //     const userId = loggedInUser ? loggedInUser._id : loggedInGuest._id;
  //     const eventSource = new EventSource(
  //       `${BaseURI}/sse/sse/${userId}/${new Date().toString()}`
  //     );

  //     eventSource.onmessage = async (event) => {
  //       console.log(event.data);
  //       try {
  //         if (JSON.parse(event.data) instanceof Object) {
  //           console.log(event.data);
  //           store.dispatch(setOrderNotification({ OrderNotification: event.data }));
  //           const eventData = JSON.parse(event.data);
  //           const messages = eventData.status;
  //           const sound = new Audio(messageSound);
  //           if (messages === "rejected" || messages === "missed" || messages === "accepted" ) {
  //             try {
  //               const resp = await putNotificationservice(eventData.orderId, eventData.status, userId);
  //               store.dispatch(setNotification({ Notification: resp.notification }));
  //               await sound.play();
  //             } catch (error) {
  //               console.error('Error:', error);
  //             }
  //           } else if (messages === "ready") {
  //             setOpenDialog(true);
  //             setIdOrder(eventData.orderId);
  //             setStatusMessage(eventData.status);
  //             try {
  //               const resp = await putNotificationservice(eventData.orderId, eventData.status, userId);
  //               store.dispatch(setNotification({ Notification: resp.notification }));
  //               await sound.play();
  //             } catch (error) {
  //               console.error('Error:', error);
  //             }
  //           }


  //         }
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     };

  //     return () => {
  //       eventSource.close();
  //     };
  //   }
  // }, []);

  /* -------------------end sse------------------------------ */

  // console.log = () => {};
  // console.error = () => {};
  // console.warn = () => {};
  // console.info = () => {};
  // console.debug = () => {};
  console.log("info")
  const Notifications = useSelector((state) => state.ModalNotification.Notification);
  return (
    <div className="App">
      <Loader isLoading={isLoading} />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        pauseOnHover={false}
      />
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <StyledOffCanvasBody>
          <OrderItems>
            {idorder && (
              <h4 style={{ textTransform: 'uppercase' }}>
                Your order # {idorder.substring(0, 4)}
                <br />
                <h4 className="d-flex justify-center" style={{ color: '#2CC94F' }}>
                  is    {statusmessage} <DoneIcon></DoneIcon>
                  <IconButton aria-label="close" onClick={handleCloseDialog} style={{ position: 'absolute', top: '8px', right: '8px' }}>
                    <CloseIcon />
                  </IconButton>
                </h4>
                <br />
                <div className="d-flex justify-center">
                  <QrCode style={{ width: '100px', height: '100px' }} value={idorder} />
                </div>
              </h4>
            )}
          </OrderItems>
        </StyledOffCanvasBody>
      </Dialog>
      {/* <ToastContainer /> */}
      <I18nextProvider i18n={i18n}>
        <Elements stripe={stripePromise}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<FirstPage />} />
              <Route path="/home" element={<Home />} />
              <Route path="/company/:id" element={<StoreScreen />} />
              <Route path="/select-store/:id" element={<SelectStore />} />
              <Route path="/page404" element={<Page404 />} />
              <Route path="/ordershistory/:storeId" element={<OrdersHistory />} />
              {/* <Route path="/favoritesorders" element={<FavoritesOrders />} /> */}
              <Route path="/verify-email/:userId" element={<VerifyEmail />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
              {/* <Route path="/*" element={<Navigate to="/" />} /> */}

              {/*  */}
            </Routes>
          </BrowserRouter>
        </Elements>
      </I18nextProvider>
    </div>
  );
}
const StyledOffCanvasBody = styled(Offcanvas.Body)`
  padding: 20px;
  background-color: #fff;
`;
const OrderItems = styled.div`
  margin-bottom: 5px;
  
  background-color: #fff;
  
  padding: 20px;
`;

export default App;