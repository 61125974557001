import React from "react";
import styled from "styled-components";
import AddressInput from "../AddressInput/AddressInput";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTranslation } from "react-i18next";
import WorkIcon from "@mui/icons-material/Work";
import HomeIcon from "@mui/icons-material/Home";
import GroupIcon from "@mui/icons-material/Group";
import AddIcon from "@mui/icons-material/Add";
import { AddDeliveryAddress, GetAdressDeliveryByUserId } from "../../shared/slice/DeliveryAdresse/DeliveryAdresseService";
import { store } from "../../shared";
import {
  setAdresses,
  setDeliveryAdress,
  setDisable,
  setLocation,
  setModeSelectedId,
  setOutOfRange,
  setProduct,
} from "../../shared/slice/restaurant/RestaurantSlice";
import { getProductByStoreByMode } from "../../shared/slice/restaurant/RestaurantService";
import { useNavigate } from "react-router-dom";
import {
  setModeDelivery,
  setModesModal,
} from "../../shared/slice/ModalMode/ModalMode";
import { setOrder } from "../../shared/slice/order/OrderSlice";
import { resetPromo } from "../../shared/slice/promos/PromosSlice";
import { setScroll } from "../../shared/slice/scroll/ScrollSlice";

function NewAddresse({add,newAdresse}) {
  const [coordinates, setCoordinates] = useState({ lat: null, lon: null });

  const [deliveryInformation, setDeliveryInformation] = useState({
    type: "",
    note: "",
  });
  const location = useSelector((state) => state.restaurant.location);
  const outOfRange = useSelector((state) => state.restaurant.outOfRange);
  const deliveryAdress = useSelector((state) => state.restaurant.deliveryAdress);
  const restaurantSelected = useSelector((state) => state.restaurant.restaurantSelected);
  const modeDelivery = useSelector((state) => state.modalMode.modeDelivery);
  const loggedInUser = useSelector(
    (state) => state.authentification.loggedInUser
  );
  const scroll = useSelector((state) => state.scroll.scroll);
  const modeId = useSelector((state) => state.restaurant.modeId);
  const disable = useSelector((state) => state.restaurant.disable);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSelectLocation = (selectedLocation) => {
    console.log("selectedLocation", selectedLocation);
    if (selectedLocation) {
      setCoordinates({
        lat: selectedLocation.value.lat,
        lon: selectedLocation.value.lon,
      });
      store.dispatch(setLocation(selectedLocation));
    }
  };
  // Calculate the distance between two sets of latitude and longitude coordinates
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Earth radius in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in kilometers
    return distance;
  };
  const calculateDistanceFromInput = (location) => {
    if (location) {
      console.log("location", location, "------------");
      const distance = calculateDistance(
        location.value.lat,
        location.value.lon,
        restaurantSelected.latitude,
        restaurantSelected.longitude
      ).toFixed(2);
      if (distance > restaurantSelected.rangeValue) {
        store.dispatch(setOutOfRange({outOfRange: true}));
        store.dispatch(setDisable({ disable: true }));
      } else {
        store.dispatch(setDisable({ disable: false }));
        store.dispatch(setOutOfRange({outOfRange: false}));
      }
    } else {
      store.dispatch(setDisable({ disable: true }));
    }
  };
  // console.log("disable//nawadresse", disable);
  console.log("location//newadresse", location);
  useEffect(() => {
    calculateDistanceFromInput(location);
  }, [location]);

  //Save New Delivery Adresse
  const handleSave = async () => {
    try {
      const addAdresseresponse = await AddDeliveryAddress(
        loggedInUser._id,
        location.label,
        deliveryInformation.type,
        location.value.lat,
        location.value.lon,
        deliveryInformation.note,
      );
      console.log(addAdresseresponse);
      if(addAdresseresponse){
        store.dispatch(
          setDeliveryAdress({
            deliveryAdress: location.label,
          })
        );
        const res = await GetAdressDeliveryByUserId(loggedInUser._id);
        store.dispatch(setAdresses(res));
      }
    } catch (error) {
      // Handling any errors that occur during the process
      console.error("Error adding address:", error);
    }
    setDeliveryInformation({
    type: "",
    note: "",
  });
  };

  //Mode delivery confirmation button 
  const selectMode = async () => {
    if (disable) {
      console.log("disable select fun");
      return;
    } else {
      if (add) { 
      try {
        const addAdresseresponse = await AddDeliveryAddress(
          loggedInUser._id,
          location.label,
          deliveryInformation.type,
          location.value.lat,
          location.value.lon,
          deliveryInformation.note,
        );
        console.log(addAdresseresponse);
        if(addAdresseresponse){
          console.log("done");
          store.dispatch(
            setDeliveryAdress({
              deliveryAdress: location.label,
            })
          );
        }
      } catch (error) {
        // Handling any errors that occur during the process
        console.error("Error adding address:", error);
      }
    }
      getProductByStoreByMode(restaurantSelected._id, modeId)
        .then((res3) => {
          store.dispatch(setProduct({ product: res3 }));
          store.dispatch(setModeSelectedId({ modeSelectedId: modeId }));
        })
        .catch((err) => {
          console.log("Page not found");
          navigate(`/page404`);
        });
      store.dispatch(setModesModal({ modesModal: false }));
      store.dispatch(setModeDelivery({ modeDelivery: false }));
      store.dispatch(setOrder({ order: [] }));
      store.dispatch(resetPromo());
      store.dispatch(setScroll({ scroll: 0 }));
      store.dispatch(setDeliveryAdress({ deliveryAdress: location.label }));
      setDeliveryInformation({
        type: "",
        note: "",
      });
      // Initialize array to store order IDs
      //   localStorage.clear();
      Object.keys(localStorage).forEach((key) => {
        if (key.startsWith("QuantityInCart_")) {
          localStorage.removeItem(key);
        }
      });
    }
  };
  return (
    <div>
      <NewAdresseField>
        {(add || newAdresse) ? (
          <React.Fragment>
            <AddressInput
              onSelectLocation={onSelectLocation}
              calculateDistanceFn={calculateDistanceFromInput}
            />
            {outOfRange && (
              <div>
                <span
                  style={{
                    height: "30px",
                    fontSize: "13px",
                    color: "red",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <ErrorOutlineIcon />
                  {t(
                    `You are out of delivery range (${restaurantSelected.rangeValue}Km away from store)`
                  )}
                </span>
              </div>
            )}
            <AdresseType>
              <p style={{ fontSize: "17px" }}>Addresse Type :</p>
              <TypeButtons>
                <TypeAdresse
                  onClick={() => {
                    setDeliveryInformation({
                      ...deliveryInformation,
                      type: t("Home"),
                    });
                  }}
                  style={{
                    backgroundColor:
                      deliveryInformation.type === t("Home") ? "#9b9b9b" : "",
                  }}
                >
                  <HomeIcon
                    style={{
                      fontSize: "22px",
                      marginRight: "7px",
                    }}
                  />
                  {t("Home")}
                </TypeAdresse>
                <TypeAdresse
                  onClick={() => {
                    setDeliveryInformation({
                      ...deliveryInformation,
                      type: t("Work"),
                    });
                  }}
                  style={{
                    backgroundColor:
                      deliveryInformation.type === t("Work") ? "#9b9b9b" : "",
                  }}
                >
                  <WorkIcon
                    style={{
                      fontSize: "17px",
                      marginRight: "7px",
                    }}
                  />
                  {t("Work")}
                </TypeAdresse>
                <TypeAdresse
                  onClick={() => {
                    setDeliveryInformation({
                      ...deliveryInformation,
                      type: t("Friend"),
                    });
                  }}
                  style={{
                    backgroundColor:
                      deliveryInformation.type === t("Friend")
                        ? "#9b9b9b"
                        : "",
                  }}
                >
                  <GroupIcon
                    style={{
                      fontSize: "22px",
                      marginRight: "7px",
                    }}
                  />
                  {t("Friend")}
                </TypeAdresse>
                <TypeAdresse
                  onClick={() => {
                    setDeliveryInformation({
                      ...deliveryInformation,
                      type: t("Other"),
                    });
                  }}
                  style={{
                    backgroundColor:
                      deliveryInformation.type === t("Other")
                        ? "#9b9b9b"
                        : "",
                  }}
                >
                  <AddIcon
                    style={{
                      fontSize: "22px",
                      marginRight: "7px",
                    }}
                  />
                  <span>{t("Other")}</span>
                </TypeAdresse>
              </TypeButtons>
              {deliveryInformation.type === "Other" && (
                <input
                  type="text"
                  placeholder={t(" Ex : Park")}
                  style={{
                    border: "1px solid lightgrey",
                    borderRadius: "8px",
                    width: "100%",
                    height: "40px",
                    backgroundColor: "#f2f4f8",
                    color: "#666",
                  }}
                  onChange={(e) => {
                    setDeliveryInformation({
                      ...deliveryInformation,
                      type: t("Other"),
                    });
                  }}
                  // onKeyDown={(e) =>{ e.stopPropagation()}}
                />
              )}
            </AdresseType>
  
            <div style={{ margin: "8px 0" }}>
              <p style={{ float: "left", fontSize: "17px" }}>
                {t("Note for the delivery man :")}{" "}
                {/* ajouter la traduction*/}
              </p>
              <input
                type="text"
                placeholder={t(" Ex : Building or entry code")}
                style={{
                  border: "1px solid lightgrey",
                  borderRadius: "8px",
                  width: "100%",
                  height: "40px",
                  backgroundColor: "#f2f4f8",
                  color: "#666",
                  marginTop: "7px",
                }}
                maxLength={280}
                onChange={(e) => {
                  setDeliveryInformation({
                    ...deliveryInformation,
                    note: e.target.value,
                  });
                }}
              />
            </div>
          </React.Fragment>
        ) : null}
        {modeDelivery ? (
          <SelectModeButton disable={disable} onClick={selectMode}>
            Select Mode
          </SelectModeButton>
        ) : (
          <AddAdresse onClick={() => handleSave()}>{t("Save")}</AddAdresse>
        )}
      </NewAdresseField>
    </div>
  );
  
}
const SelectModeButton = styled.button`
  color: ${(props) => (props.disable ? "#fff" : "#fff")};
  background: ${(props) =>
    props.disable ? "var(--primaryColorLight)" : "var(--primaryColor)"};
  border: none;
  border-radius: 25px;
  width: 90%;
  height: 40px;
  font-size: 19px;
  margin-bottom: 10px;
  cursor: ${(props) => (props.disable ? "not-allowed !important" : "pointer")};
  &:hover {
    background: ${(props) => (props.disable ? "#fff" : "#fff")};
    border: 1px solid
      ${(props) =>
        props.disable ? "var(--primaryColorLight)" : "var(--primaryColor)"};
    color: ${(props) =>
      props.disable ? "var(--primaryColorLight)" : "var(--primaryColor)"};
  }
`;
const NewAdresseField = styled.div``;
const TypeAdresse = styled.button`
  border: 1px solid lightgrey;
  border-radius: 30px;
  margin: 10px;
  padding: 5px 10px;
  width: 100px;
  display: flex;
  align-items: center;
  background-color: rgb(245 245 245 / 96%);
`;
const TypeButtons = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  font-size: 15px;
  font-family: "QuickSand";
  @media (max-width: 768px) {
    width: 250px;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns */

    grid-column-gap: 0px;
    // grid-row-gap: 0.5px;
    place-items: center;
  }
`;
const AddAdresse = styled.button`
  width: 80%;
  height: 40px;
  border: 1px solid lightgrey;
  border-radius: 8px;
  margin: 10px;
  background-color: var(--primaryColor);
`;
const AdresseType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 8px 0px;
`;
// const NewAdresseButton = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border: 1px solid lightgrey;
//   border-radius: 5px;
//   box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
//     rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
//   width: 100%;
//   height: 50px;
//   margin: 16px 0px;
//   cursor: pointer;
// `;
export default NewAddresse;
