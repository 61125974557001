import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { store } from "../../../../shared/index";
// import { setOrder } from "../../../shared/slice/order/OrderSlice";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getSymbolFromCurrency from "currency-symbol-map";
import { useTranslation } from "react-i18next";
import { setScroll } from "../../../../shared/slice/scroll/ScrollSlice";
import { setSelectedPromos } from "../../../../shared/slice/promos/PromosSlice";

// SubmitOrderPromo({setNotes, selectedPromo, setSelectedPromo, product, selectedSize, objectOptions, counter, checkedOption, checkedIndices, number })

function SubmitOrderPromo({
  selectedPromo, setSelectedPromo,
  product,
  selectedSize,
  objectOptions,
  counter,
  checkedOption,
  checkedIndices,
  number,
}) {
  // console.log("selectedPromo",selectedPromo);
  const { t } = useTranslation();
  const scroll = useSelector((state) => state.scroll.scroll)
  const order = useSelector((state) => state.order.order);
  const currency = useSelector((state) => state.restaurant.menu.currency);
  const frais = useSelector((state) => state.restaurant.frais);
  const taux = useSelector((state) => state.restaurant.taux);
  const apply = useSelector((state) => state.restaurant.apply);
  const [quantity, setQuantity] = useState(1); // quantité d'ordre
const [priceHt, setPriceHt] = useState(0); // prix ht des options
  const [priceOf1unite, setPriceOf1unite] = useState(
    () => apply === "product" ? frais !== null ? (frais*quantity) : selectedSize.price * (taux / 100) *quantity : 0
  );
  // const [priceOf1unite, setPriceOf1unite] = useState(0);
  // console.log("price", priceOf1unite);
  // console.log(isButtonEnabled);
  const groupName = selectedSize.optionGroupe;
  const subOption = selectedSize.subOptionSelected;
  // console.log("subOption", subOption);

  //-----------------calcul de prix de produit-------------//
  useEffect(() => {
    let p = 0;
let pHt = 0;
    for (let i = 0; i < objectOptions.length; i++) {
      let priceoptions = 0;
let priceoptionsHt = 0;
      if (objectOptions[i].options.length > 0) {
        for (let j = 0; j < objectOptions[i].options.length; j++) {
          priceoptions += objectOptions[i].options[j].price;
priceoptionsHt += objectOptions[i].options[j].priceHt;
        }
      }
      const price =
        (objectOptions[i].price + priceoptions) * objectOptions[i].quantity;
const priceHt =
        (objectOptions[i].priceHt + priceoptionsHt) * objectOptions[i].quantity;
      if (price) {
        p += price;
      pHt += priceHt;
      }
    }
    setPriceOf1unite(
      (apply === "product"
        ? frais !== null
          ? frais
          : selectedSize.price * (taux / 100)
        : 0) +
        (selectedSize.price + p) * quantity
    );
    setPriceHt((selectedSize.priceHt + pHt) * quantity);

  }, [selectedSize, objectOptions, number, quantity, checkedIndices.length]);
// console.log("priceHt",priceHt);
  
  const taxElement = objectOptions
  .map((item) => {
    const itemTaxElement = [{ [item.tax]: item.taxPrice.toFixed(2) }];
    const optionTaxElement = item.options.map((optionTax) => {
      return { [optionTax.tax]: optionTax.taxPrice.toFixed(2) };
    });
    return itemTaxElement.concat(optionTaxElement);
  })
  .flat();

  const TaxCalculator = (taxElement) => {
    // Create an object to store the total for each unique tax rate
    const taxTotals = taxElement.reduce((acc, tax) => {
      const taxRate = Object.keys(tax)[0];
      const taxAmount = Object.values(tax)[0];
      const roundedTax = taxRate;

      if (acc[roundedTax]) {
        acc[roundedTax] += parseFloat(taxAmount);
      } else {
        acc[roundedTax] = parseFloat(taxAmount);
      }
      return acc;
    }, {});

    if (taxTotals[selectedSize.tax]) {
      taxTotals[selectedSize.tax] += selectedSize.price - selectedSize.priceHt;
    } else {
      taxTotals[selectedSize.tax] = selectedSize.price - selectedSize.priceHt;
    }
    return taxTotals;
  };
  
  const getFormattedData = (data) => {
    const getIndexesOfLowest =
      data.products
        .map((product, index) => [product.product.price, index])
        .sort(([a], [b]) => a - b)
        .slice(0, data.promo.number2)
        .map(([, index]) => index)
    // console.log(getIndexesOfLowest);

    for (let i = 0; i < data.products.length; i++) {
      data.products[i].price_after_discount = data.products[i].price
      for (let j = 0; j < getIndexesOfLowest.length; j++) {
        if (getIndexesOfLowest[j] === i) {
          const discount = data.promo.discount / 100;
          const tax = data.products[i].product.tax / 100;
          const Product_price = data.products[i].product.price;
          const currentPrice = data.products[i].price;
          const initialPriceHt = Product_price / (1 + tax);
          const Product_price_after_discount = Product_price - (Product_price * discount);
          const Product_priceHt_after_discount = Product_price_after_discount / (1 + tax);
      
          data.products[i].price_after_discount = Product_price_after_discount + (currentPrice - Product_price);
          data.products[i].priceHt = data.products[i].priceHt - initialPriceHt + Product_priceHt_after_discount;
      
          const currentTax = data.products[i].taxes[data.products[i].product.tax];
         
          data.products[i].taxes[data.products[i].product.tax] = currentTax - (Product_price*tax) + (Product_price_after_discount * tax );
        }

        }
      }
    return data
  }

  const handleSubmit = () => {

    // Check if the button should be enabled
    let optionGroupe = {};
    let subOptionGroup = {};
    const isMainOptionsValid = groupName?.every((optionGroup, sectionIndex) => {
      const totalQuantityInSection = Object.keys(counter).reduce(
        (total, key) => {
          if (key.startsWith(`${sectionIndex}-`)) {
            total += counter[key];
          }
          return total;
        },
        0
      );
      const sectionCheckedIndices = checkedIndices.filter((index) =>
        index.startsWith(`${sectionIndex}`)
      );
      const forceMin = optionGroup.force_min;
      if (forceMin > 0) {
        if (
          (forceMin <= sectionCheckedIndices.length ||
            forceMin <= totalQuantityInSection) === false
        ) {
          optionGroupe = {
            id: optionGroup._id,
            min: forceMin,
            name: optionGroup.name,
          };
        }
        return (
          forceMin <= sectionCheckedIndices.length ||
          forceMin <= totalQuantityInSection
        );
      } else {
        return true;
      }
    });
    const isSubOptionsValid = subOption?.every((optionGroup, sectionIndex) => {
      const sectionCheckedOption = checkedOption.filter((index) =>
        index.includes(optionGroup.key)
      );
      // console.log("sectionCheckedOption", sectionCheckedOption);/////
      // console.log("checkedOption", checkedOption);
      if (optionGroup.required === true) {
        // console.log(optionGroup);
        const forceMin = optionGroup.forceMin;
        if (!(forceMin <= sectionCheckedOption.length)) {
          subOptionGroup = {
            id: optionGroup.key,
            min: forceMin,
            name: optionGroup.option,
          };
        }
        // console.log(forceMin <= sectionCheckedOption.length);
        return forceMin <= sectionCheckedOption.length;
      } else {
        return true;
      }
    });
    if (isMainOptionsValid && isSubOptionsValid) {
const taxTotals = TaxCalculator(taxElement);
      objectOptions.sort((a, b) => a.index - b.index);
      const newOrder = {
        product: {
          id: product._id,
          image: product.image,
          name: product.name,
          description: product.description,
          price: (apply === "product" ? frais !== null ? frais : selectedSize.price * (taux / 100) : 0) + (selectedSize.price) ,
          size: selectedSize.size,
tax: selectedSize.tax,
        },
        options: objectOptions,
        quantity: quantity,
        price: priceOf1unite,
        priceHt: priceHt,
        taxes: taxTotals,
        note: selectedSize.note,
      };

      let data = selectedPromo.products
      data[data.length - 2].selected = false
      data[data.length - 2].product = newOrder

      setPriceOf1unite(() => apply === "product" ? frais !== null ? (frais*quantity) : selectedSize.price * (taux / 100) *quantity : 0);

      if (data.length > selectedPromo.promo.promos.length) {
        data.pop()
        for (let i = 0; i < data.length; i++) {
          data[i] = data[i].product
        }

        let formattedData = {
          promo: selectedPromo.promo,
          products: data
        }

        formattedData = getFormattedData(formattedData)
        store.dispatch(setSelectedPromos({
          selectedPromo: formattedData
        }))
        // console.log("formattedData",formattedData);

        setSelectedPromo({
          state: false,
          promo: undefined,
          products: [{ product: undefined, selected: false }]
        })
        store.dispatch(setScroll({ scroll: 0}));

      } else {
        setSelectedPromo({
          ...selectedPromo,
          products: data
        })
      }
    } else {
      // If either main or sub-options are not valid, handle accordingly
      if (!isMainOptionsValid) {
        const invalidMainOption = document.getElementById(
          `mainOption-${optionGroupe.id}`
        );
        if (invalidMainOption) {
          invalidMainOption.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
          invalidMainOption.style.border = "2px solid #F36767";
          setTimeout(() => {
            invalidMainOption.style.border = "2px solid #fff";
          }, 3000);
          toast.error(`${t('select at least')} ${optionGroupe.min} ${t('of')} ${optionGroupe.name}`, {
            position: "top-right",
            autoClose: 2000,
            pauseOnHover: false,
          });
        }
      } else if (!isSubOptionsValid) {
        const invalidSubOption = document.getElementById(
          `subOption-${subOptionGroup.id}`
        );
        if (invalidSubOption) {
          invalidSubOption.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "start",
          });
          invalidSubOption.style.border = "2px solid #F36767";
          invalidSubOption.style.padding = "10px";
          setTimeout(() => {
            invalidSubOption.style.border = "none";
            invalidSubOption.style.padding = "none";
          }, 3000);
          toast.error(`${t('select at least')} ${subOptionGroup.min} ${t('of')} ${subOptionGroup.name}`, {
            position: "top-right",
            autoClose: 2000,
            pauseOnHover: false,
          });
        }
      }
    }


  };


  const handleDown = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };
  // button plus de quantité
  const handleUp = () => {
    setQuantity(quantity + 1);
  };
  return (
    <div>
      <BorderTop>
        <div className="quantity-container">
          {/* Quantity
          <button className="button-minus" onClick={handleDown}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              className="styles__StyledInlineSvg-sc-12l8vvi-0 jFpckg"
            >
              <path
                d="M8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H8Z"
                fill="currentColor"
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
                fill="currentColor"
              ></path>
            </svg>
          </button>
          <div>{quantity}</div>
          <button className="button-plus" onClick={handleUp}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              className="styles__StyledInlineSvg-sc-12l8vvi-0 jFpckg"
            >
              <path
                d="M12 7C12.5523 7 13 7.44772 13 8V11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11V8C11 7.44772 11.4477 7 12 7Z"
                fill="currentColor"
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                fill="currentColor"
              ></path>
            </svg>
          </button> */}
          <>
            <button

              type="submit"
              className="btn-add-order"
              onClick={handleSubmit}
            // disabled={!isButtonEnabled}
            >
              {selectedPromo.products.length > selectedPromo.promo.promos.length ? t("Finish") : t("Next")} {parseFloat(priceOf1unite).toFixed(2) === "NaN" ? null : (parseFloat(priceOf1unite).toFixed(2))}
            </button>
            <ToastContainer
              position="top-center"
              autoClose={2000}
              pauseOnHover={false}
            />
          </>
        </div>
      </BorderTop>
    </div>
  );
}
const BorderTop = styled.div`
  border-top: 1px solid #fff;
`;
export default SubmitOrderPromo;
