import React, { useEffect, useState } from 'react'
import Form from "react-bootstrap/Form";
import Modal from "react-modal";
import { useSelector } from 'react-redux';
import { setEmail, setModalLogin, setModalPrincipal, setModalResetPassword, setModalSendEmail } from '../../shared/slice/ModalLogin/ModalLoginSlice';
import { store } from '../../shared';
import { forgetPassword, login, sendEmail } from '../../shared/slice/auth/AuthService';
import { setLoggedInUser, setToken, setmodalCheckout } from '../../shared/slice/auth/AuthSlice';
import { eatorder } from '../../assets/images/exports';
import styled from 'styled-components';
import { setScroll } from '../../shared/slice/scroll/ScrollSlice';
import { useTranslation } from 'react-i18next';
import { toast } from "react-toastify";

export default function ModalLogin() {
    const { t } = useTranslation();
    const loginModal = useSelector((state) => state.modalLogin.modalLogin);
    const emailExist = useSelector((state) => state.modalLogin.emailExist)
    const [passwordError, setPasswordError] = useState(undefined);
    const email = useSelector((state) => state.modalLogin.email)
    // const [email, setEmail] = useState("");

    const [password, setPassword] = useState("");
    const scroll = useSelector((state) => state.scroll.scroll)

    
    useEffect(() => {
        if (loginModal) {
            store.dispatch(setScroll({ scroll: 1}));

        }
    }, [loginModal]);

    const handleClose = () => {
        store.dispatch(setModalPrincipal({ modalPrincipal: false }));
        store.dispatch(setModalLogin({ modalLogin: false }));
        store.dispatch(setScroll({ scroll: 0}));
        store.dispatch(setEmail({ email: "" }))
        
        // setEmail("");
        setPassword("");

    };
   
    const handleEmailChange = (e) => {
        // setEmail(e.target.value);
        store.dispatch(setEmail({ email: e.target.value }))

    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleCloseLogin = () => {
        setPasswordError(undefined)

        store.dispatch(setModalLogin({ modalLogin: false }));

        // setEmail("");
        store.dispatch(setEmail({ email: "" }))

        setPassword("");
        store.dispatch(setScroll({ scroll: 0 }));

        
  

    };
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisibility = () => {
      setPasswordShown(!passwordShown);
    };
    const modelchekoutButton = useSelector((state) => state.authentification.modelchekoutButton)
    const checkout = async () => {
        if (modelchekoutButton) {
            store.dispatch(setmodalCheckout({ modalCheckout: true }))
            console.log ("setmodalCheckout", modelchekoutButton)
        }
    };
    const handleLogin = async () => {
        
            try {
                const user = {
                    email: email,
                    password: password,
                };
                const response = await login(user);
                if (response.user.verifid) {
                    store.dispatch(setToken({ token: response.token }))
                    store.dispatch(setLoggedInUser({ user: response.user }))
                    checkout()
                    handleClose();
                    // window.location.reload();

                    // console.log(response);
                } else if (!user.verifid) {
                    const emailResponse = await sendEmail(response.data.user._id);
                    if (emailResponse) {
                        setPassword("");
                        store.dispatch(setModalLogin({ modalLogin: false }));
                        store.dispatch(setModalSendEmail({ modalSendEmail: true }));
                    } else {
                        console.error("Error sending verification email");
                    }
                } else {
                    console.error("Login failed");
                }


            }    catch (error) {
                console.error("Error during login:", error);
                setPasswordError(error.response.data.error);
            }
        
    };
    const handleForgetPassword = async () => {
        try {
            const response = await forgetPassword({ email });
            if (response) {
                console.log("aaaaaaaaaaaaaaaaaaaaaaaaaa....");
                //store.dispatch(setModalResetPassword({ modalResetPassword: true }));
                //store.dispatch(setModalLogin({ modalLogin: false }));
                //store.dispatch(setScroll({ scroll: 0}));
                toast.success("Email has been sent ! Please verify your email")

            }
        } catch (err) {
            console.error(err);
        }


    };
    return (
        <StyledModalLogin
            isOpen={loginModal}
            onRequestClose={handleCloseLogin}

        >
            <img
                src={eatorder}
                style={{
                    width: "150px",
                    height: "50px",
                    objectFit: "contain",
                    marginLeft: "29%"
                }}
            ></img>

            <h3 className="mb-5 mt-3">{t("Sign in")}</h3>
            <Form.Control
                type="email"
                style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '5%', width: '90%', borderRadius: '25px' }}
                placeholder={t('Email Address')}
                id="email"
                value={email}
                onChange={handleEmailChange}
                onKeyDown={e => e.stopPropagation()}
            />
 <div style={{ position: 'relative', marginTop: '10px', marginBottom: '10px', marginLeft: '5%', width: '90%' }}>
            <Form.Control
                type={passwordShown ? 'text' : 'password'}
                // className={`form-control ${passwordError && "is-invalid"}`}
                style={{ paddingRight: '30px', borderRadius: '25px' }}
                placeholder={t("Password")}
                id="password"
                value={password}
                onChange={handlePasswordChange}
                
            />
                  <span
        onClick={togglePasswordVisibility}
        style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: '2',
        }}
      >
        {passwordShown ? '🙈' : '👁️'}
      </span>
      </div>
            {
                <div className="error-message">{t(passwordError)}</div>
            }
            <LoginButton onClick={handleLogin}>
                {t("Login")}
            </LoginButton>
            <div

                style={{ color: "#eb1700",cursor : "pointer",textDecoration: "underline" }}
                onClick={handleForgetPassword}
            >
                {t("Forget password")}
            </div>
        </StyledModalLogin>
    )
}

function ReactModalAdapter({ className, modalClassName, ...props }) {
    return (
        <Modal
            className={modalClassName}
            portalClassName={className}
            {...props}
        />
    )
}

const StyledModalLogin = styled(ReactModalAdapter).attrs({
    modalClassName: 'Modal',
    overlayClassName: 'Overlay'
})`
    .Modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: #fff;
        padding: 20px;
        border-radius: 15px;
        height:400px;
        width: 400px;
        text-align: center;
    }
        @media (max-width: 768px) {
            .Modal{
                width: 90%;
                min-width:90%;
            }
        }
    
    .Overlay {
        background: rgba(0, 0, 0, 0.2);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }
  `
const LoginButton = styled.button`
    color:white;
    background:#eb1700;;
    border:none;
    border-radius: 25px;
    width: 100%;
    height: 40px;
    font-size: 19px;
    margin-bottom:10px;

  &:hover{
    background: #fff;
    border:1px solid #eb1700;;
    color: #eb1700;;
  }
  ` 