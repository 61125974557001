import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import SelectOption from "./selectOption/SelectOption.js";
import SubmitOrder from "./SubmitOrder.js";
import ClearIcon from "@mui/icons-material/Clear";
import styled from "styled-components";
import { BaseURI, store } from "../../../shared/index.js";
import { setScroll } from "../../../shared/slice/scroll/ScrollSlice.js";
import { useSelector } from "react-redux";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import getSymbolFromCurrency from "currency-symbol-map";
import { useTranslation } from "react-i18next";
// import "./ProductModal.css"
function ProductModal({ openModal, setOpenModal }) {
  const { t } = useTranslation();

  const scroll = useSelector((state) => state.scroll.scroll);
  const currency = useSelector((state) => state.restaurant.menu?.currency);
  const [selectedSize, setSelectedSize] = useState({
    size: " ",
    optionGroupe: [],
    subOptionGroup: [],
    subOptionSelected: [],
    price: Number,
    taxe: Number,
  });
  const [objectOptions, setObjectOptions] = useState([]); // objet d'option // stay here
  // console.log(objectOptions);
  const [counter, setCounter] = useState({}); //counter
  // const [currency, setCurrency] = useState("");
  const [number, setNumber] = useState(0); // stay here
  const [checkedIndices, setCheckedIndices] = useState([]);
  const [checkedOption, setCheckedOption] = useState([]);
  const [product, setProduct] = useState({
    ...openModal.product,
    size: openModal.product.size.map((size) => ({
      ...size,
      optionGroups: [...openModal.product.optionGroups, ...size.optionGroups],
    })),
  });
  const frais = useSelector((state) => state.restaurant.frais);
  const taux = useSelector((state) => state.restaurant.taux);
  const apply = useSelector((state) => state.restaurant.apply);
  const open = openModal.open;
  useEffect(() => {
    if (openModal) {
      store.dispatch(setScroll({ scroll: 1 }));
    }
  }, [openModal]);

  useEffect(() => {
    product.size.length === 1 && handleChange(product.size[0].name);
  }, []);

  const handleChange = (event) => {
    const rad = event?.target?.value || event;
    const findSize = product.size.find((grpe) => grpe.name === rad);
    const sub = findSize.optionGroups.flatMap((groupe) => groupe.options);
    const suboption = sub.flatMap((option) => option.subOptionGroup);
    const tax = product.taxes[0]?.tax.rate;
    setSelectedSize({
      size: rad,
      optionGroupe: findSize.optionGroups,
      subOptionGroup: suboption,
      subOptionSelected: [],
      price: findSize.price,
      priceHt: findSize.price / (1 + tax / 100),
      tax: tax,
    });
    setObjectOptions([])
    setCounter({})
    setCheckedOption([])
    setCheckedIndices([])

  };
  const groupName = selectedSize.optionGroupe;

  return (
    <Modal
      isOpen={open}
      contentLabel="optionGroups"
      overlayClassName="OverlayOption"
      className="ModalSize"
      style={{ width: "50%", height: "80%" }}
    >
      <ButtonCloseContainer>
        <h4 style={{ paddingLeft: "18px",fontFamily:"QuicksandBold" }}>{product.name}</h4>
        <ButtonClose
          onClick={() => {
            setOpenModal({
              product: undefined,
              open: false,
            });
            setSelectedSize({});
            store.dispatch(setScroll({ scroll: 0}));
          }}
        >
          <ClearIcon style={{ fontSize: "27px" }} />
        </ButtonClose>
      </ButtonCloseContainer>
      <ContentContainer groupName={groupName}>
        <div className="product-popup">
          <div>
            <div>
              <div>
                <img
                  src={`${BaseURI}/combined-uploads/${product.image}`}
                  alt=""
                  className="product-image"
                ></img>
                <div>
                  <p style={{fontSize:"0.8rem"}}>{product.description}</p>
                </div>
              </div>
              <div>
              <div className="radio-container">
                  {product.size.length > 1 &&
                    product.size.map((grp, index) => (
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic radio toggle button group"
                      >
                        <input
                          type="radio"
                          className="btn-check "
                          name="btnradio"
                          value={grp.name}
                          id={index}
                          autoComplete="off"
                          onChange={(event) => handleChange(event)}
                        />
                        <label
                          className={`btn btn-size ${
                            selectedSize.size === grp.name
                              ? "btnSizeClicked"
                              : ""
                          }`}
                          style={{display:"flex",flexDirection: "column", alignItems: "center"}}
                          htmlFor={index}
                        >
                          <span style={{fontWeight:"bold",fontSize:"22px"}}>
                          {grp.name}{" "}
                          </span>
                          <small>
                            {parseFloat(grp.price + (apply === "product"
                              ? frais !== null
                                ? frais
                                : grp.price * (taux / 100)
                              : 0)).toFixed(2)}
                            {getSymbolFromCurrency(currency)}
                          </small>
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            {groupName ? (
              <div>
                <SelectOption
                  product={product}
                  selectedSize={selectedSize}
                  setNumber={setNumber}
                  setCounter={setCounter}
                  counter={counter}
                  setCheckedIndices={setCheckedIndices}
                  checkedIndices={checkedIndices}
                  setCheckedOption={setCheckedOption}
                  checkedOption={checkedOption}
                  objectOptions={objectOptions}
                />

                {selectedSize.size !== " " ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "7%",
                        marginBottom: "2%",
                      }}
                    >
                      <p style={{ fontFamily: "QuicksandBold" }}>
                        {t('Additional note')} :
                      </p>
                    </div>

                    <TextareaAutosize
                      id="noteTextarea"
                      aria-label=""
                      minRows={3}
                      minCols={20}
                      placeholder={t("leave your additional note")}
                      style={{
                        width: "90%",
                        border: "1px solid grey",
                        borderRadius: "5px",
                        textAlign: "left",
                        marginBottom: "80px",
                        paddingLeft: "5px",
                        fontFamily: "QuicksandRegular",
                      }}
                      value={selectedSize.note}
                      onChange={(e) =>
                        setSelectedSize((prevSize) => ({
                          ...prevSize,
                          note: e.target.value,
                        }))
                      }
                    />
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </ContentContainer>
      {selectedSize.size !== " " && (
        <div>
          <Footer>
            <SubmitOrder
              selectedSize={selectedSize}
              objectOptions={objectOptions}
              counter={counter}
              number={number}
              checkedIndices={checkedIndices}
              checkedOption={checkedOption}
              product={product}
              setSelectedSize={setSelectedSize}
              setOpenModal={setOpenModal}
              setCheckedIndices={setCheckedIndices}
              setCheckedOption={setCheckedOption}
              setCounter={setCounter}
              setObjectOptions={setObjectOptions}
            />
          </Footer>
        </div>
      )}
    </Modal>
  );
}
export default ProductModal;
const ButtonCloseContainer = styled.div`
  width: 100%;
  margin-top: 5px;
  height: 27px;
  background-color: #fff;
  position: sticky;
  top: 0px;
  text-align: left;
  border-bottom: 1px solid #33333347;
  height: 35px;
  z-index: 1000;
`;
const ButtonClose = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 10px;
  cursor: pointer;
  background-color: white;
  margin-top: 2px;
`;
// const ModalContainer = styled.div`
//   position: relative;
// `;

const Footer = styled.div`
  width: 100%;
  padding: 6px;
  border-top: 1px solid #33333347;
  background: #fff;
  @media (max-width: 768px) {
    position: absolute;
    bottom: 0;
  }
`;
// const Footer = styled.div`
// position: sticky;
// bottom: 0;
// width: 100%;
// padding: 8px;
// background-color: #fff;
// `;
const ContentContainer = styled.div`
  overflow-y: scroll;
  //  height: "80%";
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

 
`;
// const ModalContainer = styled.div`
//   // position: relative;
//   // height: 100%;
// `;
/* CSS for customizing the scrollbar */
// &::-webkit-scrollbar {
//   width: 0;
//   height: 0;
// }
// overflow-y: ${props => {
//   switch (true) {
//     case props.groupName.length > 0:
//       return "scroll";
//     default:
//       return "hidden";
//   }
// }};
